import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({ motherMenu, motherMenuLink, activeMenu, activeMenuLink }) => {
  
  return (
    <div className="page-titles">		
		<ol className="breadcrumb">
			<li className="breadcrumb-item">
				<Link to={motherMenuLink}>{motherMenu}</Link>
			</li>
			<li className="breadcrumb-item active">
			<Link to={activeMenuLink}>{activeMenu}</Link>
			</li>
		</ol>
    </div>
  );
};

export default PageTitle;

import React, { createContext, useContext, useState, useEffect } from "react";
import { getMyRosters } from "../services/SharedFunctions";

const RosterContext = createContext([]);

export const RosterProvider = ({ children }) => {
  const [rostersList, setRostersList] = useState([]);
  const [selectedRoster, setSelectedRoster] = useState(() => {
    let roster = [];
    if (
        localStorage.getItem("selectedRoster") &&
        localStorage.getItem("selectedRoster") !== "undefined" &&
        localStorage.getItem("selectedRoster") !== "null"
    ) {
      roster = JSON.parse(localStorage.getItem("selectedRoster"));
    }

    return roster;
  });

  useEffect(() => {
    let teamsMap = {};
    getMyRosters()
        .then((result) => {
          if (result !== undefined) {
            result.map((roster) => {
              const lastTeam = roster.teams[roster.teams.length - 1];
              const teamId = lastTeam ? lastTeam.team_id : "No Team";

              if (!teamsMap[teamId]) {
                teamsMap[teamId] = {
                  label: teamId,
                  options: [],
                };
              }

              teamsMap[teamId].options.push({
                value: roster.rosterId,
                label: roster.name,
              });
            });
            setRostersList(Object.values(teamsMap));
          }
        })
        .catch(error => {
          console.error('Error fetching rosters:', error);
        });
  }, []);

  useEffect(() => {
    if (rostersList.length > 0 && !selectedRoster) {
      const firstTeam = rostersList[0];
      const firstRoster = firstTeam.rosters[0];
      if (firstRoster) {
        setSelectedRoster(firstRoster);
        localStorage.setItem("selectedRoster", JSON.stringify(firstRoster));
      }
    }
  }, [rostersList, selectedRoster]);

  return (
      <RosterContext.Provider
          value={{ selectedRoster, setSelectedRoster, rostersList }}
      >
        {children}
      </RosterContext.Provider>
  );
};

export const useRoster = () => useContext(RosterContext);
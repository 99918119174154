// sharedFunctions.js
import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import {v4 as uuidv4} from "uuid";

PouchDB.plugin(PouchDBFind);
const localDb = new PouchDB(process.env.REACT_APP_POUCHDB, {
  // adapter: 'memory'
});
//const remoteDb = new PouchDB(process.env.REACT_APP_COUCHDB_URL, {"auth.username": process.env.REACT_APP_COUCHDB_USER, "auth.password": process.env.REACT_APP_COUCHDB_PASSWORD});
const remoteDb = new PouchDB(process.env.REACT_APP_COUCHDB_URL);

export { localDb, remoteDb };

const docRoster = {
  type: "roster",
  firstname: "",
  lastname: "",
  positions: [
    /*"Point guard", "Shooting guard", "Small forward", "Power forward", "Center"*/
  ],
  country: "",
  teams: [
    /*
    {
      "team_id": "team_001",
      "start_date": "2023-01-01",
      "end_date": "2023-12-31"
    },
    {
      "team_id": "team_002",
      "start_date": "2024-01-01",
      "end_date": "2024-12-31"
    }
    */
  ],
  height: 0,
  weight: 0,
  yearsOfExperience: 0,
  dateOfBirth: "",
  image: "",
  privacy: {
    profile: "public",
    statistics: "public",
    matches: "public",
    biometric: "public",
    training: "public",
  },
  target: {
    ppg: 0,
    rpg: 0,
    apg: 0,
    efr: 0,
  },

  career_stats: {
    total_points: 0,
    total_rebounds: 0,
    total_assists: 0,
    games_played: 0,
    total_blocks: 0,
    total_steals: 0,
    field_goal_percentage: 0,
  },
  season_stats: [
    /*
    {
      "season": "2024-2025",
      "team_id": "team_002",
      "points": 900,
      "rebounds": 450,
      "assists": 210,
      "games_played": 75,
      "blocks": 25,
      "steals": 35,
      "field_goal_percentage": 47.5,
      
    }
    */
  ],
  stared: [],
  owner: [],
  editor: [],
};

const docTeam = {
  type: "team",
  name: "",
  short: "",
  logo: "",
  city: "",
  state: "",
  country: "",
  conference: "",
  division: "",
  colors: {
    primary: "",
    secondary: "",
    tertiary: "",
  },
  league_positions: [],
  rosters: [],
  stared: [],
  owner: [],
  editor: [],
};

const docUser = {
  type: "user",
  user_id: 0,
  stared: {
    teams: [],
    rosters: [],
  },
  owner: {
    teams: [],
    rosters: [],
  },
  editor: {
    teams: [],
    rosters: [],
  },
};

const docMatch = {
  type: "match",
  rosterId: "",
  date: "",
  time: "",
  location: "",
  home: true,
  teams: {
    home: {
      team_id: "",
      score: 0,
    },
    opponent: {
      team_id: "",
      score: 0,
    },
  },
  over: false,

  owner: "",
};

const docMatchStats = {
  type: "match_stats",
  match_id: "",
  player_id: "",
  team_id: "",

  twoPts: 0,
  twoPtsAttempt: 0,
  threePts: 0,
  threePtsAttempt: 0,
  ft: 0,
  ftAttempt: 0,
  assist: 0,
  block: 0,
  foul: 0,
  rebound: 0,
  reboundOff: 0,
  reboundDef: 0,
  steal: 0,
  turnover: 0,

  triple_double: 0,
  double_double: 0,
  quintuple_double: 0,
  fivebyfive: 0,

  efficiency: 0,
  minutes: 0,
  player_efficiency: 0,
  performance_index: 0,

  owner: "",
};

const docExcercise = {
  type: "exercise",
  title: "",
  description: "",
  image: "",
  video: "",
  tags: [
    /*"cardio", "strength", "flexibility"*/
  ],
  equipment: [
    /*"treadmill", "jump rope", "dumbbells"*/
  ],
  duration: 0,
  intensity: 0,
  difficulty: 0,
  body_parts: [
    /*"legs", "arms", "core"*/
  ],
  instructions: "",

  owner: "",
};

const docTrainingSession = {
  type: "training_session",
  scheduled_date: "",
  scheduled_time: "",
  location: "",
  exercise_ids: [
    /*"course sur tapis 30 min", "saut à la corde 15 min"*/
  ],
  status: "scheduled", // scheduled, completed, cancelled,

  owner: "",
};

const docMeasurements = {
  type: "measurements",
  rosterId: "",
  date: new Date(),
  height: {
    value: 0,
    unit: '',
  },
  weight: {
    value: 0,
    unit: '',
  },
  body_fat_percentage: 0,
  wingspan: {
    value: 0,
    unit: '',
  },
  bmi: 0,
}

const docBiometric = {
  //"_id": "biometric_20240515_player001",
  type: "biometric_data",
  roster_id: "",
  date: "", //2024-05-15
  measurements: {
    heart_rate: 0, //72,
    blood_pressure: "", //"120/80",
    oxygen_saturation: 0, //98,
    body_fat_percentage: 0, //14.5,
    weight: 0, //75,
    height: 0, //180,
    temperature: 0, //37,
    arm_span: 0, //185,
    waist: 0, //85,
    chest: 0, //100,
    hips: 0, //100,
    thigh: 0, //50,
    calf: 0, //35,
    neck: 0, //40,
    shoulder: 0, //50,
    biceps: 0, //30,
    forearm: 0, //25,
    wingspan: 0, //185,
    vertical_jump: 0, //30,
    broad_jump: 0, //250,
    shuttle_run: 0, //10,
    three_cone_drill: 0, //7,
    forty_yard_dash: 0, //5,
    bench_press: 0, //100,
    squat: 0, //150,,

    owner: "",
  },
};

localDb
  .sync(remoteDb, {
    live: true,
    retry: true,
  })
  .on("change", function (info) {
    // Gérer les changements lors de la synchronisation
    //console.log("Synchronization change:", info);
  })
  .on("paused", function (err) {
    // Réplication en pause (peut être dû à une perte de connexion)
    //console.log("Synchronization paused:", err);
  })
  .on("active", function () {
    // Réplication reprise (nouvelles modifications de réplication)
    //console.log("Synchronization resumed");
  })
  .on("denied", function (err) {
    // Une réplication a été refusée (probablement à cause d'un document non valide)
    //console.log("Synchronization denied:", err);
  })
  .on("complete", function (info) {
    // Gérer la complétion de la synchronisation
    //console.log("Synchronization completed:", info);
  })
  .on("error", function (err) {
    // Gérer l'erreur de synchronisation
    //console.log("Synchronization error:", err);
  });

function fetchRostersForUser(userId) {
  return localDb
    .query("roster_views/by_user_role", { key: userId })
    .then(function (result) {
      return result.rows.map((row) => ({
        rosterId: row.value._id,
        name: row.value.firstname + " " + row.value.lastname,
        positions: row.value.positions,
        age: row.value.dob ? calculateAge(row.value.dob) : "",
        nationality: row.value.doc.country,
        image: row.value.image,
        teams: row.value.teams, 
      }));
    })
    .catch(function (err) {
      console.error("Error querying rosters:", err);
    });
}

export const getMyRosters = () => {
  if (localStorage.getItem("userDetails") === null) {
    return Promise.resolve([]);
  }

  return fetchRostersForUser(
    JSON.parse(localStorage.getItem("userDetails")).authorization.user_id
  );
};

export const saveRoster = (item) => {
  if (item._id) {
    return localDb.put(item);
  } else {
    item = {
      ...docRoster,
      ...item,
    };
    item._id = uuidv4().toString();
    item.owner.push(
      JSON.parse(localStorage.getItem("userDetails")).authorization.user_id
    );
    return localDb.put(item);
  }
};

export const getRoster = (id) => {
  if (!id) return Promise.resolve(docRoster);
  return localDb
    .get(id)
    .then((response) => {
      var roster = docRoster;
      roster = {
        ...docRoster,
        ...response,
      };
      return roster;
    })
    .catch((err) => console.log("Error getting Roster:", err));
};

export const deleteRoster = (id) => {
  return localDb
    .get(id)
    .then((doc) => {
      return localDb.remove(doc);
    })
    .catch((err) => console.log("Error deleting Roster:", err));
};

const calculateAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const getRosterPosition = () => {
  return [
    { value: "PG", label: "Point Guard", type: "Guards", pos: 1 },
    { value: "PG/SG", label: "Combo Guard", type: "Guards", pos: 1 },

    { value: "SG", label: "Shooting Guard", type: "Guards", pos: 2 },
    { value: "GF", label: "Guard-Forward / Swingman", type: "Guards", pos: 2 },

    { value: "SF", label: "Small Forward", type: "Forwards", pos: 3 },
    { value: "PG/PF", label: "Point Forward", type: "Forwards", pos: 3 },

    { value: "PF", label: "Power Forward", type: "Forwards", pos: 4 },
    {
      value: "SF/PF",
      label: "Combo Forward / Stretch Four",
      type: "Forwards",
      pos: 4,
    },

    { value: "C", label: "Center", type: "Center", pos: 5 },
    { value: "FC", label: "Forward-Center / Bigman", type: "Center", pos: 5 },
  ];
};

function fetchTeamsForUser(userId) {
  return localDb
    .query("team_views/by_user_role", { key: userId })
    .then(function (result) {
      return result.rows.map((row) => ({
        teamId: row.value._id,
        role: row.value.role,
        name: row.value.name,
        short: row.value.short,
        logo: row.value.logo,
        city: row.value.city,
      }));
    })
    .catch(function (err) {
      console.error("Error querying rosters:", err);
    });
}

export const getMyTeams = () => {
  if (localStorage.getItem("userDetails") === null) return [];

  return fetchTeamsForUser(
    JSON.parse(localStorage.getItem("userDetails")).authorization.user_id
  );
};

export const saveTeam = (item) => {
  if (item._id) {
    return localDb.put(item);
  } else {
    item = {
      ...docTeam,
      ...item,
    };
    item._id = uuidv4().toString();
    item.owner.push(
      JSON.parse(localStorage.getItem("userDetails")).authorization.user_id
    );
    return localDb.put(item);
  }
};

export const getTeam = (id) => {
  if (!id) return Promise.resolve(docTeam);
  return localDb
    .get(id)
    .then((response) => {
      var team = docTeam;
      team = {
        ...docTeam,
        ...response,
      };
      return team;
    })
    .catch((err) => console.log("Error getting Team:", err));
};

export const deleteTeam = (id) => {
  return localDb
    .get(id)
    .then((doc) => {
      return localDb.remove(doc);
    })
    .catch((err) => console.log("Error deleting Team:", err));
};

// Function to save user's favorite teams and rosters
export const saveUserStared = (userId, teams, rosters) => {
  const userFavorites = {
    _id: userId,
    type: "user",
    stared: {
      teams: teams,
      rosters: rosters,
    },
  };

  return localDb.put(userFavorites);
};

export const addStared = (userId, type, id) => {
  userId =
    userId ||
    JSON.parse(localStorage.getItem("userDetails")).authorization.user_id;
  const itemId = "user_" + userId;

  return localDb
    .get(itemId)
    .then((doc) => {
      if (type === "team") {
        if (!doc.stared.teams.includes(id)) {
          doc.stared.teams.push(id);
        }
      } else if (type === "roster") {
        if (!doc.stared.rosters.includes(id)) {
          doc.stared.rosters.push(id);
        }
      }
      return localDb.put(doc);
    })
    .catch((err) => {
      console.log("Error adding favorite:", err);
      if (err.name === "not_found") {
        // Document doesn't exist, create it
        var doc = {
          _id: itemId,
          user_id: userId,
          ...docUser,
        };
        if (type === "team") {
          if (!doc.stared.teams.includes(id)) {
            doc.stared.teams.push(id);
          }
        } else if (type === "roster") {
          if (!doc.stared.rosters.includes(id)) {
            doc.stared.rosters.push(id);
          }
        }
        return localDb.put(doc);
      } else {
        console.log("Error loading user document:", err);
      }
    });
};

export const removeStared = (userId, type, id) => {
  userId =
    userId ||
    JSON.parse(localStorage.getItem("userDetails")).authorization.user_id;
  let itemId = "user_" + userId;
  return localDb
    .get(itemId)
    .then((doc) => {
      if (type === "team") {
        doc.stared.teams = doc.stared.teams.filter((team) => team !== id);
      } else if (type === "roster") {
        doc.stared.rosters = doc.stared.rosters.filter(
          (roster) => roster !== id
        );
      }
      return localDb.put(doc);
    })
    .catch((err) => console.log("Error removing favorite:", err));
};

// Function to get user's favorite teams and rosters
export const getUserStared = (userId) => {
  userId =
    userId ||
    JSON.parse(localStorage.getItem("userDetails")).authorization.user_id;
  let itemId = "user_" + userId;
  return localDb
    .get(itemId)
    .then((doc) => {
      console.log("User favorites:", doc.stared);
      return doc.stared;
    })
    .catch((err) => console.log("Error getting user favorites:", err));
};

export const getAllRosters = () => {
  return localDb
    .find({
      selector: { type: "roster" },
    })
    .then((response) => {
      return response.docs;
    })
    .catch((err) => console.log("Error getting all rosters:", err));
};

export const getRosterMatches = (id) => {
  return localDb
      .find({
        selector: {
          rosterId: id,
          type: "match"
        },
      })
      .then((response) => {
        return response.docs;
      })
      .catch((err) => console.log("Error getting all matches:", err));
};

export const getDashboardMatches = (
  user_id = "",
  timeline = "",
  week = "",
  limit = 100
) => {
  if (user_id === "")
    user_id = JSON.parse(localStorage.getItem("userDetails")).authorization
      .user_id;
  return localDb
    .find({
      selector: { type: "roster", user_id: user_id },
    })
    .then((response) => {
      const rosters = response.docs;
      //console.log("All Rosters:", rosters);
      const rosterIds = rosters.map((roster) => roster._id);
      //console.log("Roster IDs:", rosterIds);
      return localDb
        .createIndex({
          index: {
            fields: ["date"], //specify all fields
          },
        })
        .then(() => {
          // Maintenant, vous pouvez trier sur le champ "date"
          const selector = {
            $and: [
              { type: "match" },
              { rosterId: { $in: rosterIds } },
              { date: { $gte: null } },
            ],
          };

          if (week !== "") {
            var now = new Date();
            if (week == "current") {
              now = new Date();
            } else {
              now = week;
            }

            var dayOfWeek = now.getDay(); // 0-6, 0 is Sunday, 1 is Monday, etc.
            if (dayOfWeek === 0) dayOfWeek = 7; // Make Sunday 7
            var numDay = now.getDate();

            var startOfWeek = new Date(now); // Start from today
            startOfWeek.setDate(numDay - dayOfWeek); // Shift to the first day of the week
            startOfWeek.setHours(0, 0, 0, 0); // Set start of day

            var endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6); // Shift to the last day of the week
            endOfWeek.setHours(23, 59, 59, 999); // Set end of day

            selector.$and[2].date.$gte = startOfWeek.toISOString();
            selector.$and[2].date.$lte = endOfWeek.toISOString();
          }

          if (timeline === "previous") {
            selector.$and[2].date.$lt = new Date().toISOString();
          } else if (timeline === "upcoming") {
            // selector.$and[2].date.$gte = new Date().toISOString();
            selector.$and[3].over = 0;
          }

          return localDb
            .find({
              selector: selector,
              sort: [{ date: "asc" }],
            })
            .then((response) => {
              const matches = response.docs;
              //console.log("All Matches:", matches);
              return matches;
            });
        })
        .catch((err) => console.log("Error creating index:", err));
    });
};

export const getDashboardRosters = (user_id = "", limit = 3) => {
  if (user_id === "")
    user_id = JSON.parse(localStorage.getItem("userDetails")).authorization
      .user_id;
  return localDb
    .find({
      selector: { type: "roster", user_id: user_id },
    })
    .then((response) => {
      const rosters = response.docs;
      //return rosters;
      //console.log("All Rosters:", rosters);
      const rosterIds = rosters.map((roster) => roster._id);
      //console.log("Roster IDs:", rosterIds);
      return [
        {
          firstname: "All",
          lastname: "Rosters",
          _id: "1",
          statistics: {
            game: {
              date: "2024-05-27",
              team: "FCB",
            },
            training: {
              date: "2024-05-24",
              type: "Training",
            },
            performance: "W +72 pts",
          },
        },
        {
          firstname: "All",
          lastname: "Rosters 2",
          _id: "2",
          statistics: {
            game: "2024-05-27",
            training: "2024-05-24",
            performance: "W +72 pts",
          },
        },
      ];
    });
};

export const getRosterMatch = (rosterId, matchId) => {
  return localDb
    .find({
      selector: [{ type: "match" }, { rosterId: rosterId }, { _id: matchId }],
    })
    .then((response) => {
      const match = response.docs[0];
      //console.log("Match:", match);
      return match;
    })
    .catch((err) => console.log("Error getting match:", err));
};

export const getMatch = (id) => {
  return localDb
    .get(id)
    .then((response) => {
      //console.log("Match:", response);
      return response;
    })
    .catch((err) => console.log("Error getting roster:", err));
};

export const saveMatch = (match) => {
  //console.log('Match:', match);
  if (match._id) {
    localDb
      .put(match)
      .then((response) => console.log("Match updated:", response))
      .catch((err) => console.log("Error updating match:", err));
  } else {
    match._id = uuidv4().toString();
    match.type = "match";

    localDb
      .put(match)
      .then((response) => console.log("Match created:", response))
      .catch((err) => console.log("Error creating match:", err));
  }
  return getMatch(match._id);
};

// FUNCTION FOR MEASUREMENTS //
export const saveMeasurements = (item) => {
  if (item._id) {
    return localDb.put(item);
  }

  item = {
    ...docMeasurements,
    ...item,
  };
  item._id = uuidv4().toString();

  return localDb
      .put(item)
      .then((response) => console.log("Measurements created:", response))
      .catch((err) => console.log("Error creating measurements:", err));
};

// Function to dump all documents
export const dumpDatabase = () => {
  localDb
    .allDocs({ include_docs: true })
    .then((result) => {
      const data = result.rows.map((row) => row.doc);
      downloadJSON(data, "database-export.json");
    })
    .catch((err) => {
      console.error("Error exporting database:", err);
    });
};

// Function to trigger download
export const downloadJSON = (data, filename) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download.json";
  a.click();
  URL.revokeObjectURL(url);
};

import React, { useState, useEffect, Fragment } from "react";
import { Card } from "react-bootstrap";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import user from "../../images/avatar/default.png";
import {
  getRoster,
  saveRoster,
  getRosterPosition,
} from "../../services/SharedFunctions";
import swal from "sweetalert";
import { Row, Col, Accordion } from "react-bootstrap";
import { useParams, Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from 'react-select-country-list';

const defaultAccordion = [
  {
    title: "Tips for Setting Objectives",
    text: "",
    bg: "primary",
  },
];

const EditRoster = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");
  const countries = countryList().getData();
  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  //For Image upload
  const [file, setFile] = React.useState(null);
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setTimeout(function () {
      var src = document.getElementById("saveImageFile").getAttribute("src");
      //addFormData.image = src;
    }, 200);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    saveRoster(selectedItem)
      .then((data) => {
        selectedItem._id = data.id;
        selectedItem._rev = data.rev;
        swal("Success!", "Roster saved successfully!", "success");
        setSelectedItem(selectedItem);
        navigate('/my-rosters');
      })
      .catch((error) => {
        swal("Error!", "Error saving roster!", "error");
      });
  };

  const handleEditMultipleFormChange = (event) => {
    const { name, options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    
    setSelectedItem(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleTargetEditFormChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevItem) => ({
      ...prevItem,
      target: {
        ...prevItem.target,
        [name]: parseInt(value),
      },
    }));
  };

  const handlePrivacyEditFormChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevItem) => ({
      ...prevItem,
      privacy: {
        ...prevItem.privacy,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    getRoster(itemId)
      .then((data) => {
        setSelectedItem(data);
      })
      .catch((error) => {
        console.error("Error fetching roster:", error);
      });
  }, []);

  if (!selectedItem) {
    return null; // or return a loading spinner
  }

  return (
    <>
      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="row page-titles ">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link to={"/my-rosters"}>My Rosters</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>{selectedItem.firstname} {selectedItem.lastname}</Link>
                </li>
              </ol>
            </div>
            <Card>
              <Form onSubmit={handleEditFormSubmit}>
                <Card.Header>
                  <Card.Title as="h5">Edit Roster</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Firstname</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstname"
                          value={selectedItem.firstname}
                          onChange={handleEditFormChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Lastname</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastname"
                          value={selectedItem.lastname}
                          onChange={handleEditFormChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Photo</Form.Label>
                        <Form.Control
                          type="file"
                          name="logo"
                          onChange={fileHandler}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <img
                        id="saveImageFile"
                        src={selectedItem.logo ? selectedItem.logo : user}
                        alt="team logo"
                        width="100"
                        height="100"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                          type="text"
                          as={"select"}
                          name="gender"
                          value={selectedItem.gender}
                          onChange={handleEditFormChange}
                        >
                          <option value="">Select gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Date of Birth</Form.Label>
                        <div>
                          <DatePicker
                            className="form-control"
                            selected={selectedItem.dateOfBirth !== "" ? new Date(selectedItem.dateOfBirth) : ''}
                            onChange={(date) =>
                              setSelectedItem((prevItem) => ({
                                ...prevItem,
                                dateOfBirth: date,
                              }))
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <h3 className="mt-4 mb-3"></h3>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Positions</Form.Label>
                        <Form.Control
                          as="select"
                          name="positions"
                          value={selectedItem.positions}
                          onChange={handleEditMultipleFormChange}
                          multiple
                        >
                          <option value="PG">Point Guard</option>
                          <option value="SG">Shooting Guard</option>
                          <option value="SF">Small Forward</option>
                          <option value="PF">Power Forward</option>
                          <option value="C">Center</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Nationality</Form.Label>
                        <Form.Control
                            as="select"
                            name="country"
                            value={selectedItem.country ? selectedItem.country : selectedCountry }
                            onChange={handleEditFormChange}
                        >
                          <option value="">Select your country</option>
                          {countries.map((country) => (
                              <option key={country.value} value={country.label}>
                                {country.label}
                              </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <h3 className="mt-4 mb-3">Statistics Objectives</h3>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-3">
                        <Form.Label>Target Points</Form.Label>
                        <Form.Control
                            type="number"
                          placeholder="Enter PPG"
                          name="ppg"
                          value={selectedItem.target.ppg}
                          onChange={handleTargetEditFormChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Target Rebounds</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter RPG"
                          name="rpg"
                          value={selectedItem.target.rpg}
                          onChange={handleTargetEditFormChange}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group className="mb-3">
                        <Form.Label>Target Assists</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter APG"
                          name="apg"
                          value={selectedItem.target.apg}
                          onChange={handleTargetEditFormChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Target Efficiency</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter EFR"
                          name="efr"
                          value={selectedItem.target.efr}
                          onChange={handleTargetEditFormChange}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <p>
                      Reasonable goals for Points Per Game (PPG), Rebounds Per
                      Game (RPG), Assists Per Game (APG), and Efficiency Rating
                      (ER) largely depend on the level of competition (NBA,
                      college, amateur leagues, etc.), the player's role in the
                      team, and their individual skills. Below are some
                      generalizations based on professional levels, especially
                      the NBA, to provide a reference framework:
                    </p>

                    <Accordion className="accordion accordion-danger-solid">
                      {defaultAccordion.map((data, i) => (
                        <Accordion.Item key={i} eventKey={`${i}`}>
                          <Accordion.Header className="accordion-header">
                            {" "}
                            <span className="fw-bold">{data.title}</span>
                          </Accordion.Header>
                          <Accordion.Collapse
                            eventKey={`${i}`}
                            className="accordion__body"
                          >
                            <div className="accordion-body">
                              <Row className="justify-content-between">
                                <Col lg={6} className=" mb-3">
                                  <h4 className="mb-2 mt-3">
                                    Points Per Game (PPG)
                                  </h4>
                                  <p className="card-text">
                                    <div>
                                      <span className="fw-bold">
                                        Average player:
                                      </span>{" "}
                                      10 to 15 PPG
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Good player:
                                      </span>{" "}
                                      16 to 20 PPG
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Team star:
                                      </span>{" "}
                                      21 to 25 PPG
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Superstar/League leader:
                                      </span>{" "}
                                      More than 25 PPG
                                    </div>
                                  </p>
                                </Col>
                                <Col lg={6} className=" mb-3">
                                  <h4 className="mb-2 mt-3">
                                    Rebounds Per Game (RPG)
                                  </h4>
                                  <p className="card-text">
                                    <div>
                                      <span className="fw-bold">
                                        Average player (non-center):
                                      </span>{" "}
                                      3 to 6 RPG
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Good rebounder / Center:
                                      </span>{" "}
                                      7 to 9 RPG
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Rebounding leader:
                                      </span>{" "}
                                      10 RPG or more
                                    </div>
                                  </p>
                                </Col>
                                <Col lg={6} className=" mb-3">
                                  <h4 className="mb-2 mt-3">
                                    Assists Per Game (APG)
                                  </h4>
                                  <p className="card-text">
                                    <div>
                                      <span className="fw-bold">
                                        Average player:
                                      </span>{" "}
                                      2 to 5 APG
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Good playmaker:
                                      </span>{" "}
                                      6 to 9 APG
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Team leader/League leader:
                                      </span>{" "}
                                      10 APG or more
                                    </div>
                                  </p>
                                </Col>
                                <Col lg={6} className=" mb-3">
                                  <h4 className="mb-2 mt-3">
                                    Efficiency Rating (ER)
                                  </h4>
                                  <p className="card-text">
                                    <div>
                                      <span className="fw-bold">
                                        Average player:
                                      </span>{" "}
                                      Around 10 to 15
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Efficient player:
                                      </span>{" "}
                                      16 to 20
                                    </div>
                                    <div>
                                      <span className="fw-bold">
                                        Very efficient player:
                                      </span>{" "}
                                      More than 20
                                    </div>
                                  </p>
                                </Col>
                              </Row>

                              <h4 className="mt-4">Specific Examples:</h4>
                              <p>
                                In the NBA, a player like LeBron James often
                                averages more than 25 PPG, 7 RPG, and 7 APG,
                                with an efficiency rating often well above 20.
                                At the collegiate level, the figures might be
                                somewhat lower, with point leaders often around
                                20 PPG, rebound leaders around 10 RPG, and
                                assist leaders around 6 APG.
                              </p>

                              <h4 className="mt-4">More Tips:</h4>
                              <ul>
                                <li>
                                  Analyze past statistics of the player to set
                                  realistic objectives.
                                </li>
                                <li>
                                  Consider the player's role: A playmaker will
                                  generally have higher APG goals, while a
                                  center will focus more on RPG.
                                </li>
                                <li>
                                  Adjust according to the level of competition:
                                  Objectives for a high school player will be
                                  much lower than for a professional player.
                                </li>
                                <li>
                                  Use comparisons: Look at the statistics of
                                  players with similar roles in similar leagues
                                  to establish competitive objectives.
                                </li>
                              </ul>
                            </div>
                          </Accordion.Collapse>
                        </Accordion.Item>
                      ))}
                    </Accordion>

                    <p>
                      When setting objectives, it is essential to remain
                      flexible and adjust them based on the player's development
                      and current performances.
                    </p>
                  </div>

                  <h3 className="mt-4 mb-3">Settings and privacy</h3>

                  <div className="row">
                    <div className="col-md-2">
                      <Form.Group className="mb-3">
                        <Form.Label>Profile</Form.Label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="profile"
                            value="private"
                            id="profile_privacy"
                            checked={selectedItem.privacy.profile === "private"}
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="private">
                            Private
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="profile"
                            value="public"
                            id="profile_public"
                            checked={selectedItem.privacy.profile === "public"}
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="public">
                            Public
                          </label>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-2">
                      <Form.Group className="mb-3">
                        <Form.Label>Matches</Form.Label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="matches"
                            value="private"
                            id="matches_privacy"
                            checked={selectedItem.privacy.matches === "private"}
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="private">
                            Private
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="matches"
                            value="public"
                            id="matches_public"
                            checked={selectedItem.privacy.matches === "public"}
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="public">
                            Public
                          </label>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-2">
                      <Form.Group className="mb-3">
                        <Form.Label>Training</Form.Label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="training"
                            value="private"
                            id="training_privacy"
                            checked={
                              selectedItem.privacy.training === "private"
                            }
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="private">
                            Private
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="training"
                            value="public"
                            id="training_public"
                            checked={selectedItem.privacy.training === "public"}
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="public">
                            Public
                          </label>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-2">
                      <Form.Group className="mb-3">
                        <Form.Label>Statistics</Form.Label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="statistics"
                            value="private"
                            id="statistics_privacy"
                            checked={
                              selectedItem.privacy.statistics === "private"
                            }
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="private">
                            Private
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="statistics"
                            value="public"
                            id="statistics_public"
                            checked={
                              selectedItem.privacy.statistics === "public"
                            }
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="public">
                            Public
                          </label>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-2">
                      <Form.Group className="mb-3">
                        <Form.Label>Biometric</Form.Label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="biometric"
                            value="private"
                            id="biometric_privacy"
                            checked={
                              selectedItem.privacy.biometric === "private"
                            }
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="private">
                            Private
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="biometric"
                            value="public"
                            id="biometric_public"
                            checked={
                              selectedItem.privacy.biometric === "public"
                            }
                            onChange={handlePrivacyEditFormChange}
                          />
                          <label className="form-check-label" htmlFor="public">
                            Public
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      The "Profile visibility" feature allows users to control
                      who can view their profile information. It provides two
                      options: "Public" and "Private".
                      <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <li>
                          <span className="fw-bold">Public:</span> When the
                          profile visibility is set to "Public", anyone can view
                          the user's profile information. This means that the
                          user's profile will be accessible to everyone,
                          including people who are not connected or friends with
                          the user.
                        </li>

                        <li>
                          <span className="fw-bold">Private:</span> On the other
                          hand, when the profile visibility is set to "Private",
                          only selected individuals or friends can view the
                          user's profile information. This means that the user's
                          profile will be restricted and accessible only to a
                          specific group of people chosen by the user.
                        </li>
                      </ul>
                      By selecting the appropriate option for profile
                      visibility, users can decide whether they want their
                      profile information to be accessible to everyone or
                      limited to a select group of individuals. This feature
                      helps users maintain control over their privacy and
                      determine who can view their personal details.
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Row>
                    <Col xs={6} className="text-start">
                      <button
                        type="button"
                        onClick={() => window.history.back()}
                        className="btn btn-secondary"
                      >
                        Back
                      </button>
                    </Col>
                    <Col xs={6}>
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            </Card>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default EditRoster;

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import swal from "sweetalert";

import { getMyRosters, deleteRoster, addStared, removeStared, getUserStared, saveMeasurements } from "../../services/SharedFunctions";
import UiPagination from "../components/bootstrap/Pagination";

const MyRosters = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(process.env.REACT_APP_ROSTER_ITEM_PER_PAGE, 10);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(true);

  const [rosters, setRosters] = useState([]);
  const [stared, setStared] = useState({ teams: [], rosters: [] });

  const [data, setData] = useState(
    document.querySelectorAll("#content_wrapper tbody tr")
  );
  const sort = 8;
  const activePage = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const changeData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    getMyRosters()
        .then((result) => {
          if (result !== undefined) {
            setRosters(result);
          }
        })
        .catch(error => {
          console.error('Error fetching rosters:', error);
        });
    getUserStared().then((result) => {
      if (result !== undefined) {
        setStared(result.rosters);
      }
    });
  }, []);

  const totalItems = rosters.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rosters.slice(indexOfFirstItem, indexOfLastItem);

  const tableData = [];
  const [deleteItem, setDeleteItem] = useState(tableData);

  const handleDelete = (id, ind) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this roster!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteRoster(id)
          .then(() => {
            swal("Poof! Your roster has been deleted!", {
              icon: "success",
            });
            const updatedrosters = rosters.filter((item, index) => index !== ind);
            setRosters(updatedrosters);
          })
          .catch((error) => {
            swal("Error!", "Error deleting roster!", "error");
          });
      } else {
        swal("Your roster is safe!");
      }
    });
  };

  const updateStared = (itemId) => {
    const userId = JSON.parse(localStorage.getItem("userDetails")).authorization.user_id;
    if (checkStared(itemId)) {
      removeStared(userId, 'roster', itemId).then((result) => {
        setStared(stared.filter(rosterId => rosterId !== itemId));
      });
    } else {
      addStared(userId, 'roster', itemId).then((result) => {
        setStared([...stared, itemId]);
      });
    }    
  }

  function checkStared(itemId) {
    if (stared) {
      if (Array.isArray(stared)) {
        return stared.includes(itemId);
      }
    }

    return false;
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [formData, setFormData] = useState({
    height: { value: '', unit: 'cm' },
    weight: { value: '', unit: 'kg' },
    body_fat_percentage: '',
    wingspan: { value: '', unit: 'cm' },
    bmi: ''
  });

  const handleOpenModal = (player) => {
    setSelectedPlayer(player);
    setFormData({
      height: player.height || { value: '', unit: 'cm' },
      weight: player.weight || { value: '', unit: 'kg' },
      body_fat_percentage: player.body_fat_percentage || '',
      wingspan: player.wingspan || { value: '', unit: 'cm' },
      bmi: player.bmi || ''
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e, field, subfield) => {
    if (subfield) {
      setFormData({
        ...formData,
        [field]: { ...formData[field], [subfield]: e.target.value }
      });
    } else {
      setFormData({ ...formData, [field]: e.target.value });
    }
  };

  const handleUnitChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: { ...formData[field], unit: e.target.value }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      rosterId: selectedPlayer.rosterId
    };

    saveMeasurements(updatedFormData);
  };


  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row page-titles ">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/dashboard"}>Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to={"/my-rosters"}>My rosters</Link>
              </li>
            </ol>
          </div>
          <div className="mb-3">
            <ul className="d-flex align-items-center">
              <li>
                <Link to={"/add-roster"} className="btn btn-primary">
                  New roster
                </Link>
              </li>
            </ul>
          </div>
          <div className="filter cm-content-box box-primary mt-5">
            <div className={`content-title`}>
              <div className="cpa">
                <i className="far fa-user me-2"></i> Rosters List
              </div>
              <div className="tools"></div>
            </div>
            <Collapse in={open2}>
              <div className="cm-content-body form excerpt">
                <div className="card-body py-3">
                  <div className="table-responsive">
                    <div
                      id="content_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table className="table table-responsive-lg table-striped table-condensed flip-content">
                        <thead>
                          <tr>
                            <th className="text-black"></th>
                            <th className="text-black">Roster</th>
                            <th className="text-black">Position</th>
                            <th className="text-black">Age</th>
                            <th className="text-black">Nat.</th>
                            <th className="text-black">Team</th>
                            <th className="text-end text-black">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        {rosters && rosters.length > 0
                            ? (rosters.map((item, ind) => (
                                <tr key={ind}>
                                  <td>
                                    <div
                                      onClick={() => updateStared(item.rosterId)}
                                      className={`btn ${ Array.isArray(stared) && stared.includes(item.rosterId) ? 'btn-secondary' : 'btn-primary'} btn-sm content-icon status m-0`}
                                    >
                                      <i className={`fa-star ${ Array.isArray(stared) && stared.includes(item.rosterId) ? 'fa-solid' : 'fa-regular'}`}></i>
                                    </div>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.positions}</td>
                                  <td>{item.age}</td>
                                  <td>{item.nationality}</td>
                                  <td>{item.teams}</td>

                                  <td className="text-end">
                                    <Link
                                        to={"/edit-roster/" + item.rosterId}
                                        className="btn btn-warning btn-sm content-icon"
                                    >
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    <Link
                                        to={"#"}
                                        className="btn btn-primary btn-sm content-icon ms-1"
                                        onClick={() => handleOpenModal(item)}
                                    >
                                      <i className="fa-solid fa-scale-balanced"></i>
                                    </Link>
                                    <Link
                                        to={"#"}
                                        className="btn btn-danger btn-sm content-icon ms-1"
                                        onClick={() =>
                                            handleDelete(item.rosterId, ind)
                                        }
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </Link>
                                  </td>
                                </tr>
                                ))
                            ) : (
                              <tr>
                                <td colSpan="7">No rosters available</td>
                              </tr>
                            )}
                        </tbody>
                      </table>

                      <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Player Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {selectedPlayer && (
                              <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                  <Form.Label>Height</Form.Label>
                                  <div className="d-flex">
                                    <Form.Control
                                        type="number"
                                        value={formData.height.value}
                                        onChange={(e) => handleInputChange(e, 'height', 'value')}
                                        placeholder="Enter height"
                                    />
                                    <Form.Control
                                        as="select"
                                        value={formData.height.unit}
                                        onChange={(e) => handleUnitChange(e, 'height')}
                                        className="ms-2"
                                    >
                                      <option value="cm">cm</option>
                                      <option value="inches">inches</option>
                                    </Form.Control>
                                  </div>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                  <Form.Label>Weight</Form.Label>
                                  <div className="d-flex">
                                    <Form.Control
                                        type="number"
                                        value={formData.weight.value}
                                        onChange={(e) => handleInputChange(e, 'weight', 'value')}
                                        placeholder="Enter weight"
                                    />
                                    <Form.Control
                                        as="select"
                                        value={formData.weight.unit}
                                        onChange={(e) => handleUnitChange(e, 'weight')}
                                        className="ms-2"
                                    >
                                      <option value="kg">kg</option>
                                      <option value="lbs">lbs</option>
                                    </Form.Control>
                                  </div>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                  <Form.Label>Body Fat Percentage (%)</Form.Label>
                                  <Form.Control
                                      type="number"
                                      step="0.1"
                                      value={formData.body_fat_percentage}
                                      onChange={(e) => handleInputChange(e, 'body_fat_percentage')}
                                      placeholder="Enter body fat percentage"
                                  />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                  <Form.Label>Wingspan</Form.Label>
                                  <div className="d-flex">
                                    <Form.Control
                                        type="number"
                                        value={formData.wingspan.value}
                                        onChange={(e) => handleInputChange(e, 'wingspan', 'value')}
                                        placeholder="Enter wingspan"
                                    />
                                    <Form.Control
                                        as="select"
                                        value={formData.wingspan.unit}
                                        onChange={(e) => handleUnitChange(e, 'wingspan')}
                                        className="ms-2"
                                    >
                                      <option value="cm">cm</option>
                                      <option value="inches">inches</option>
                                    </Form.Control>
                                  </div>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                  <Form.Label>BMI</Form.Label>
                                  <Form.Control
                                      type="number"
                                      step="0.1"
                                      value={formData.bmi}
                                      onChange={(e) => handleInputChange(e, 'bmi')}
                                      placeholder="Enter BMI"
                                  />
                                </Form.Group>

                                <Button variant="primary" type="submit" className="mt-3">
                                  Save Changes
                                </Button>
                              </Form>
                          )}
                        </Modal.Body>
                      </Modal>
                      { totalItems > itemsPerPage ?
                          <UiPagination
                              totalItems={totalItems}
                              itemsPerPage={itemsPerPage}
                              currentPage={currentPage}
                              onPageChange={handlePageChange}
                              variant="primary"
                              size="sm"
                          /> : null }
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyRosters;

import React, { useState, useEffect, Fragment } from "react";
import { useRoster } from "../../context/RosterContext";
import "react-datepicker/dist/react-datepicker.css";
import { getRoster, setRoster, saveRoster } from "../../services/SharedFunctions";
import swal from "sweetalert";
import {useParams} from "react-router-dom";


const BodyMetrics = () => {
  const { rosterId } = useParams();
  const [roster, setRoster] = useState(0);
  const [selectedItem, setSelectedItem] = useState(false);

  useEffect(() => {
    getRoster(rosterId)
        .then((data) => {
          setRoster(data);
          console.log(roster)
        })
        .catch((error) => {
          console.error("Error fetching roster:", error);
        });
  });


  return (
    <>
      <Fragment>
        <div className="row">
          <div className="col"></div>
          <div
            className="col-xl-8 center-content"
            style={{ maxWidth: "800px" }}
          >
            <div className="col-md-12">
              <div className="alert alert-success mt-5" role="alert">
                <h4 className="alert-heading">New Features Coming Soon!</h4>
                <p>
                  Get ready to take your fitness journey to the next level! We
                  are excited to announce that soon you will be able to track
                  your height, weight, and all key body measurements right here
                  on our platform. This comprehensive tracking feature will
                  enable you to monitor your physical changes and progress
                  towards your fitness goals in real time.
                </p>
                <hr />
                <p className="mb-0">
                  Stay tuned for updates and get ready to explore the benefits
                  of detailed body metrics tracking. More information on how to
                  utilize these new features will be provided upon launch.
                </p>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
      </Fragment>
    </>
  );
};

export default BodyMetrics;

import React, { useState, useEffect, Fragment } from "react";
import { Card } from "react-bootstrap";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import user from "../../images/avatar/default.png";
import { getMyRosters, getTeam, saveTeam } from "../../services/SharedFunctions";
import swal from "sweetalert";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import countryList from "react-select-country-list";

const EditTeam = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();

  const [rosters, setRosters] = useState([]);
  const [selectedRoster, setSelectedRoster] = useState('');

  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const countries = countryList().getData();

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  //For Image upload
  const [file, setFile] = React.useState(null);
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setTimeout(function () {
      var src = document.getElementById("saveImageFile").getAttribute("src");
      //addFormData.image = src;
    }, 200);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    saveTeam(selectedItem)
      .then((data) => {
        selectedItem._id = data.id;
        selectedItem._rev = data.rev;
        swal("Success!", "Team saved successfully!", "success");
        setSelectedItem(selectedItem);
        navigate('/my-teams');
      })
      .catch((error) => {
        swal("Error!", "Error saving team!", "error");
      });
  };

  const handleEditMultipleFormChange = (event) => {
    const { name, options } = event.target;
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    setSelectedItem(prevState => ({
      ...prevState,
      [name]: selectedValues
    }));
  };

  useEffect(() => {
    getTeam(itemId)
      .then((data) => {
        setSelectedItem(data);
      })
      .catch((error) => {
        console.error("Error fetching team:", error);
      });

    getMyRosters()
        .then((result) => {
          if (result !== undefined) {
            setRosters(result); // Stocker les rosters dans l'état
          }
        })
        .catch((error) => {
          console.error('Error fetching rosters:', error);
        });
  }, []);

  if (!selectedItem) {
    return null; // or return a loading spinner
  }

  return (
    <>
      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="row page-titles ">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link to={"/my-teams"}>My Teams</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>{selectedItem.name}</Link>
                </li>
              </ol>
            </div>
            <Card>
              <Form onSubmit={handleEditFormSubmit}>
                <Card.Header>
                  <Card.Title as="h5">Edit Team</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={selectedItem.name}
                          onChange={handleEditFormChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Short</Form.Label>
                        <Form.Control
                          type="text"
                          name="short"
                          value={selectedItem.short}
                          onChange={handleEditFormChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control
                          type="file"
                          name="logo"
                          onChange={fileHandler}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <img
                        id="saveImageFile"
                        src={selectedItem.logo ? selectedItem.logo : user}
                        alt="team logo"
                        width="100"
                        height="100"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          value={selectedItem.city}
                          onChange={handleEditFormChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            name="country"
                            value={selectedItem.country ? selectedItem.country : selectedCountry }
                            onChange={handleEditFormChange}
                        >
                          <option value="">Select your country</option>
                          {countries.map((country) => (
                              <option key={country.value} value={country.label}>
                                {country.label}
                              </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row><Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Select Roster</Form.Label>
                        <Form.Control
                            as="select"
                            name="rosters"
                            value={selectedItem.rosters}
                            onChange={handleEditMultipleFormChange}
                            multiple
                        >
                          {rosters.map((roster, index) => (
                              <option key={index} value={roster.id}>
                                {roster.name}
                              </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Row>
                    <Col xs={6} className="text-start">
                      <button
                        type="button"
                        onClick={() => window.history.back()}
                        className="btn btn-secondary"
                      >
                        Back
                      </button>
                    </Col>
                    <Col xs={6}>
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            </Card>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default EditTeam;

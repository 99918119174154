import React, { useContext, useEffect, useState, useReducer } from "react";

import { Link } from "react-router-dom";
/// Scroll

import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
/// Image
import profile from "../../../images/profile/17.jpg";
import { ThemeContext } from "../../../context/ThemeContext";
import Select, { components } from "react-select";

import { useRoster } from "../../../context/RosterContext";
import { getRoster } from "../../../services/SharedFunctions";



const Header = ({ onNote }) => {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  
  const { background, changeBackground } = useContext(ThemeContext);
  
  function ChangeMode() {
    if (background.value === "light") {
      changeBackground({ value: "dark", label: "Dark" });
    } else {
      changeBackground({ value: "light", label: "Light" });
    }
  }

  const { selectedRoster, setSelectedRoster, rostersList } = useRoster();
  const handleSelectRoster = (event) => {
    const selectedValue = event?.value || event?.target?.value;

    if (selectedValue) {
      getRoster(selectedValue)
          .then((result) => {
            if (result !== undefined) {
              setSelectedRoster(result);
              localStorage.setItem("selectedRoster", JSON.stringify(result));
            }
          })
          .catch((error) => {
            console.error("Error fetching roster:", error);
          });
    }
  };

  function refresh() {
    forceUpdate();
  }

  /*
  const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630", isFixed: true },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
  ];
  const flavourOptions = [
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" },
    { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" },
  ];
  const groupedOptions = [
    {
      label: "Group Name",
      options: colourOptions,
    },
    {
      label: "Another Group Name",
      options: flavourOptions,
    },
  ];
  */

  const groupStyles = {
    border: `2px dotted`,
    borderRadius: "5px",
    background: "#f2fcff",
  };

  const Group = (props) => (
    <div style={groupStyles}>
      <components.Group {...props} />
    </div>
  );

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <ul className="navbar-nav header-right" style={{ width: "100%" }}>
                <li
                  className="nav-item dropdown notification_dropdown"
                  style={{ width: "100%" }}
                >
                  <Select
                    className="col-form-label-sm input-group-sm input-group search-area d-lg-inline-flex me-3 "
                    defaultValue={{value: selectedRoster.id, label: selectedRoster.name }}
                    options={rostersList}
                    components={{ Group }}
                    onChange={handleSelectRoster}
                    name="rosterHeader"
                  />
                </li>
              <li className="nav-item">
                <form>
                  <div className="input-group search-area d-lg-inline-flex d-none me-3 ">
                    <div className="input-group-text" id="header-search">
                      <button className="bg-transparent border-0" type="button">
                        <i className="flaticon-381-search-2"></i>
                      </button>
                    </div>
                    <input
                      className={`form-control `}
                      type="text"
                      placeholder="Search Players, Teams..."
                    />
                  </div>
                </form>
              </li>
              <li
                className="nav-item dropdown notification_dropdown"
                onClick={ChangeMode}
              >
                <Link
                  to={"#"}
                  className={`nav-link bell dz-theme-mode ${
                    background.value === "dark" ? "active" : ""
                  }`}
                >
                  <i id="icon-light" className="fas fa-sun"></i>
                  <i id="icon-dark" className="fas fa-moon"></i>
                </Link>
              </li>
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  to={"my-rosters"}
                  className={`nav-link bell dz-theme-mode`}
                >
                  <i id="icon-person" className="fas fa-user"></i>
                </Link>
              </li>
              <li className="nav-item dropdown notification_dropdown">
                <Link to={"my-teams"} className={`nav-link bell dz-theme-mode`}>
                  <i id="icon-team" className="fas fa-user-group"></i>
                </Link>
              </li>

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  className="nav-link i-false"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img src={profile} width={20} alt="" />
                  <div className="header-info">
                    <span className="text-black">
                      <strong>
                        {
                          JSON.parse(localStorage.getItem("userDetails"))
                            .authorization.user_name
                        }
                      </strong>
                    </span>
                    <p className="fs-12 mb-0"></p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-2">
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">Profile </span>
                  </Link>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;

import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { format, parseISO } from "date-fns";
// import styles

import { useRoster } from "../../../context/RosterContext";

import { getRosterMatches, saveMatch } from "../../../services/SharedFunctions";

import "react-calendar/dist/Calendar.css";
import { Tab, Nav, Card, Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const UpcomingMatches = () => {
  const { selectedRoster, setSelectedRoster } = useRoster();
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    if (selectedRoster && selectedRoster._id) {
      getRosterMatches(selectedRoster._id).then((result) => {
        setMatches(result);
      }).catch((error) => {
        console.error("Error fetching roster matches:", error);
      });
    }
  }, [selectedRoster]);

  console.log(matches)
  const [show, setShow] = useState(false);
  const [team, setTeam] = useState("");
  const [date, setDate] = useState("");
  const [home, setHome] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();

    const match = {
      _id: null,
      rosterId: selectedRoster._id,
      type: "match",
      date: date,
      team: team,
      home: home,
      score_home: 0,
      score_visitors: 0,
      quarters: [],
      playing_time: '',
      twoPts: 0,
      twoPtsAttempt: 0,
      threePts: 0,
      threePtsAttempt: 0,
      ft: 0,
      ftAttempt: 0,
      assist: 0,
      block: 0,
      foul: 0,
      rebound: 0,
      steal: 0,
      turnover: 0,
      over: 0,
    };

    saveMatch(match);
    getRosterMatches(selectedRoster._id, "upcoming").then((result) => {
      setMatches(result);
    });
    handleClose();
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (!selectedRoster) {
    return null; // or return a loading spinner
  }

  return (
    <React.Fragment>
      <PageTitle
        activeMenu="Upcoming Games"
        motherMenu={selectedRoster.firstname + " " + selectedRoster.lastname}
      />
      <div className="row">
        <div className="col-xl-12">
          <Card>
            <Card.Body className="plan-list">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                  <div className="me-auto pe-3 mb-3">
                    <h4 className="text-black fs-20">Scheduled Games</h4>
                    <p className="fs-13 mb-0">
                      This page serves as a schedule displaying both upcoming
                      games and those that have not yet concluded.
                    </p>
                  </div>
                  <div className="card-action card-tabs me-4 mt-3 mt-sm-0 mb-3">
                    <Nav as="ul" className="nav nav-tabs" role="tablist">
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link
                          as="a"
                          eventKey="All"
                          className="nav-link cursor-pointer"
                          to="#All"
                        >
                          All
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link
                          as="a"
                          eventKey="Unfinished"
                          className="nav-link cursor-pointer"
                          to="#Unfinished"
                        >
                          Unfinished
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link
                          as="a"
                          eventKey="NotStarted"
                          className="nav-link cursor-pointer"
                        >
                          Not Started
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <Tab.Content className="card-body tab-content pt-2">
                  <Tab.Pane eventKey="All" className="tab-pane fade" id="All">
                    {matches &&
                      matches.map((match) => (
                        <div className="d-flex border-bottom flex-wrap pt-3 list-row align-items-center mb-2 px-3">
                          <div className="col-xl-5 col-xxl-8 col-lg-6 col-sm-8 d-flex align-items-center">
                            <div className="list-icon bgl-primary me-3 mb-3">
                              <p className="fs-24 text-primary mb-0 mt-2">
                                {parseISO(match.date).getDate()}
                              </p>
                              <span className="fs-14 text-primary">
                                {months[
                                  parseISO(match.date).getMonth()
                                ].toUpperCase()}
                              </span>
                            </div>
                            <div className="info mb-3">
                              <h4 className="fs-20 ">
                                <Link
                                  to="workout-statistic"
                                  className="text-black"
                                >
                                  {match.team
                                    ? match.team.toUpperCase()
                                    : "N/A"}{" "}
                                  @ {match.home ? "HOME" : "AWAY"}
                                </Link>
                              </h4>
                              <span
                                className={
                                  match.score_home > 0 ||
                                  match.score_visitors > 0
                                    ? "text-danger font-w600"
                                    : "text-secondary font-w600"
                                }
                              >
                                {match.score_home > 0 ||
                                match.score_visitors > 0
                                  ? "UNFINISHED"
                                  : "NOT STARTED"}
                              </span>
                            </div>
                          </div>
                          <div className="col-xl-2 col-xxl-4 col-lg-2 col-sm-4 activities mb-3 me-auto ps-3 pe-3 text-sm-center text-xl-end">
                            <span className="text-warning ms-2"></span>
                          </div>
                          <div className="col-xl-5 col-xxl-12 col-lg-4 col-sm-12 d-flex align-items-center">
                            <Link
                              to={
                                "/roster/" +
                                selectedRoster._id +
                                "/match/" +
                                match._id
                              }
                              className="btn mb-3 play-button rounded me-3"
                            >
                              <i className="las la-caret-right scale-2 me-3" />
                              {match.score_home > 0 || match.score_visitors > 0
                                ? "Edit Game"
                                : "Start Game"}
                            </Link>
                          </div>
                        </div>
                      ))}
                  </Tab.Pane>

                  <Tab.Pane
                    eventKey="Unfinished"
                    className="tab-pane  fade"
                    id="Unfinished"
                  ></Tab.Pane>
                  <Tab.Pane
                    eventKey="Finished"
                    className="tab-pane fade"
                    id="Finished"
                  ></Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>

            <Card.Footer className="border-0 text-end">
              <Button variant="primary" onClick={handleShow}>
                + New Game
              </Button>
            </Card.Footer>

            <Modal show={show} onHide={handleClose}>
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>New Game</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Team</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter team"
                      value={team}
                      onChange={(e) => setTeam(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Home"
                      checked={home}
                      onChange={(e) => setHome(e.target.checked)}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpcomingMatches;

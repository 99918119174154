import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import RecomendedSlider from "../Gymove/Home/RecomendedSlider";

import FeaturedDietMenu from "../Gymove/Home/FeaturedDietMenu";
import { ThemeContext } from "../../../context/ThemeContext";

const PlanList = loadable(() =>
  pMinDelay(import("../Gymove/Home/PlanList"), 1000)
);
const CaloriesChart = loadable(() =>
  pMinDelay(import("../Gymove/Home/CaloriesChart"), 1000)
);

const dropList = [
  {title:'Monthly'},
  {title:'Yearly'},
  {title:'Weekly'},
  {title:'Daily'},
];

const Home = () => {
  const [selectChange, setSelectChange] = useState('Monthly');

  const {changeBackground} = useContext(ThemeContext);
  useEffect(()=>{
    changeBackground({value:'light', label:'Light'})
  },[])
  return (
    <div className="row">
      <div className="col-xl-6 col-xxl-12">
        <div className="row">

        </div>
      </div>
    </div>
  );
};

export default Home;

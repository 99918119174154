import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import swal from "sweetalert";
import UiPagination from "../components/bootstrap/Pagination";

import { getMyTeams, deleteTeam, addStared, removeStared, getUserStared } from "../../services/SharedFunctions";
import {RosterProvider} from "../../context/RosterContext";
import {Nav} from "react-bootstrap";

const MyTeams = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(process.env.REACT_APP_TEAM_ITEM_PER_PAGE, 10);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(true);

  const [teams, setTeams] = useState([]);
  const [stared, setStared] = useState(false);
  const [data, setData] = useState(
    document.querySelectorAll("#content_wrapper tbody tr")
  );

  const sort = 8;

  // use effect
  useEffect(() => {
    getMyTeams().then((result) => {
      if (result !== undefined) {
        setTeams(result);
      }
    });
    getUserStared().then((result) => {
      if (result !== undefined) {
        setStared(result.teams);
        console.log(result);
      }
    });
  }, []);

  const totalItems = teams.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = teams.slice(indexOfFirstItem, indexOfLastItem);

  const tableData = [];
  const [deleteItem, setDeleteItem] = useState(tableData);

  const handleDelete = (id, ind) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this team!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTeam(id)
          .then(() => {
            swal("Poof! Your team has been deleted!", {
              icon: "success",
            });
            const updatedTeams = teams.filter((item, index) => index !== ind);
            setTeams(updatedTeams);
          })
          .catch((error) => {
            swal("Error!", "Error deleting team!", "error");
          });
      } else {
        swal("Your team is safe!");
      }
    });
  };

  const updateStared = (itemId) => {
    const userId = JSON.parse(localStorage.getItem("userDetails")).authorization.user_id;
    if(checkStared(itemId)) {
      removeStared(userId, 'team', itemId).then((result) => {
        setStared(stared.filter(teamId => teamId !== itemId));
      });
    } else {
      addStared(userId, 'team', itemId).then((result) => {
        //stared.teams.push(itemId);
        setStared([...stared, itemId]);
      });
    }    
  }

  function checkStared(itemId) {
    if(stared) {
      console.log(itemId);
      console.log(stared.includes(itemId));
      return stared.includes(itemId);
    } else {
      console.log("stared is null");
      return false;
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row page-titles ">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to={"#"}>My Teams</Link>
              </li>
            </ol>
          </div>
          {/*
                    <div className="filter cm-content-box box-primary">
                        <div className={`content-title`}>
                            <div className="cpa">
                                <i className="fas fa-filter me-2"></i>Filter
                            </div>
                            <div className="tools">
                                <Link to={"#"} className={`SlideToolHeader ${open ? 'collapse' : 'expand' }`}
                                    onClick={() => setOpen(!open)}
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>                      
                        <Collapse in={open}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body pb-3">
                                    <div className="row">
                                        <div className="col-xl-3 col-xxl-6">
                                            <input type="text" className="form-control mb-xl-0 mb-3" id="exampleFormControlInput1" placeholder="Firstname Lastname" />
                                        </div>
                                        <div className="col-xl-3 col-xxl-6">                                            
                                            <Select 
                                                isSearchable = {false}
                                                options={""} className="custom-react-select mb-3 mb-xxl-0"
                                            />
                                        </div>
                                        <div className="col-xl-3 col-xxl-6">
                                            <input type="date" name="datepicker" className=" form-control mb-xxl-0 mb-3" />
                                        </div>
                                        <div className="col-xl-3 col-xxl-6">
                                            <button className="btn btn-primary me-2" title="Click here to Search" type="button"><i className="fa-sharp fa-solid fa-filter me-1"></i>Filter</button>
                                            <button className="btn btn-danger light" title="Click here to remove filter" type="button">Remove</button>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </Collapse>   
                    </div>
                    */}
          <div className="mb-3">
            <ul className="d-flex align-items-center">
              <li>
                <Link to={"/add-team"} className="btn btn-primary">
                  New Team
                </Link>
              </li>
            </ul>
          </div>
          <div className="filter cm-content-box box-primary mt-5">
            <div className={`content-title`}>
              <div className="cpa">
                <i className="far fa-user me-2"></i> Teams List
              </div>
              <div className="tools"></div>
            </div>
            <Collapse in={open2}>
              <div className="cm-content-body form excerpt">
                <div className="card-body py-3">
                  <div className="table-responsive">
                    <div
                      id="content_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table className="table table-responsive-lg table-striped table-condensed flip-content">
                        <thead>
                          <tr>
                            <th className="text-black"></th>
                            <th className="text-black">Name</th>
                            <th className="text-black">Short</th>
                            <th className="text-black">City</th>
                            <th className="text-end text-black">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teams
                            ? teams.map((item, ind) => (
                                <tr key={ind}>
                                  <td>
                                    <div
                                      onClick={() => updateStared(item.teamId)}
                                      className={`btn ${ stared.includes(item.teamId) ? 'btn-secondary' : 'btn-primary'} btn-sm content-icon status m-0`}
                                    >
                                      <i className={`fa-star ${ stared.includes(item.teamId) ? 'fa-solid' : 'fa-regular'}`}></i>
                                    </div>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.short}</td>
                                  <td>{item.city}</td>

                                  <td className="text-end">
                                    <Link
                                      to={"/edit-team/" + item.teamId}
                                      className="btn btn-warning btn-sm content-icon"
                                    >
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    <Link
                                      to={"#"}
                                      className="btn btn-danger btn-sm content-icon ms-1"
                                      onClick={() => handleDelete(item.teamId, ind)}
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                      { totalItems > itemsPerPage ?
                        <UiPagination
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            variant="primary"
                            size="sm"
                        /> : null }
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyTeams;

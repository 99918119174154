import React, { Fragment, useReducer, useState, useEffect } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { getRoster, getMatch, saveMatch } from "../../../services/SharedFunctions";
import { useNavigate } from 'react-router-dom';
import avatar from "../../../images/avatar/default.png";
import user from './../../../images/avatar/default.png';
import DatePicker from "react-datepicker";
import { date } from "yup/lib/locale";

import PageTitle from "../../layouts/PageTitle";

import { ButtonGroup } from "react-bootstrap";
import { Accordion } from "react-bootstrap";

const Match = () => {
  const { rosterId } = useParams();
  const { matchId } = useParams();
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false); // Timer running state
  const [elapsedTime, setElapsedTime] = useState(0);

  const [roster, setRoster] = useState(0);
  const [match, setMatch] = useState(0);
  let navigate = useNavigate();

  useEffect(() => {
    getRoster(rosterId).then((result) => {
      setRoster(result);
    });
    if(matchId){
      getMatch(matchId).then((result) => {
        setMatch(result);
        
        let date = new Date(result.date);
        let formattedDate = date.toISOString().split('T')[0]; // "2024-03-23"

        //console.log(formattedDate);
        //console.log(result.team);
        //setScoreHome(result.score_home);
        //setScoreVisitors(result.score_visitors);

        //document.getElementsByName('team')[0].value = result.team;
        /*
        document.getElementsByName('home')[0].checked = result.home;
        document.getElementsByName('date')[0].value = formattedDate;
        document.getElementsByName('score_home')[0].value = result.score_home;
        document.getElementsByName('score_visitors')[0].value = result.score_visitors;
        document.getElementsByName('twoPts')[0].value = result.twoPts;
        document.getElementsByName('twoPtsAttempt')[0].value = result.twoPtsAttempt;
        document.getElementsByName('threePts')[0].value = result.threePts;
        document.getElementsByName('threePtsAttempt')[0].value = result.threePtsAttempt;
        document.getElementsByName('ft')[0].value = result.ft;
        document.getElementsByName('ftAttempt')[0].value = result.ftAttempt;
        document.getElementsByName('assist')[0].value = result.assist;
        document.getElementsByName('block')[0].value = result.block;
        document.getElementsByName('foul')[0].value = result.foul;
        document.getElementsByName('rebound')[0].value = result.rebound;
        document.getElementsByName('steal')[0].value = result.steal;
        document.getElementsByName('turnover')[0].value = result.turnover;
        */
      });
    } else {
      const match = {
        _id: null,
        rosterId: rosterId,
        type: 'match',
        date: new Date(),
        team: '',
        home:1,
        score_home: 0,
        score_visitors: 0,
        quarters: [],
        playing_time: '',
        twoPts: 0,
        twoPtsAttempt: 0,
        threePts: 0,
        threePtsAttempt: 0,
        ft: 0,
        ftAttempt: 0,
        assist: 0,
        block: 0,
        foul: 0,
        rebound: 0,
        steal: 0,
        turnover: 0,
        over: 0
      };
      saveMatch(match).then((result) => {
        setMatch(result);
        //console.log('matchId NEW:', result._id);
        navigate(`/roster/${rosterId}/match/${result._id}`);
      });
    }

      const savedElapsedTime = localStorage.getItem("timer-elapsedTime");
      const savedStartTime = localStorage.getItem("timer-startTime");
      const savedIsActive = localStorage.getItem("timer-isActive");

      if (savedElapsedTime) {
          setElapsedTime(parseInt(savedElapsedTime, 10)); // Load the elapsed time from localStorage
      }

      if (savedStartTime && savedIsActive === "true") {
          const startTime = new Date(savedStartTime).getTime();
          const currentTime = Date.now();
          const additionalTime = Math.floor((currentTime - startTime) / 1000);
          setTime(parseInt(savedElapsedTime, 10) + additionalTime); // Continue from the paused time
          setIsActive(true); // Resume the timer if it was active
      }

      let interval = null;
      if (isActive) {
          interval = setInterval(() => {
              setTime((prevTime) => prevTime + 1);
          }, 1000);
      } else if (!isActive && time !== 0) {
          clearInterval(interval); // Clear interval if paused
      }

      return () => clearInterval(interval);

  }, [isActive, time]);
  
  if (!roster) {
    return null; // or return a loading spinner
  }

  const actionNames = {
    twoPts: '2pts',
    twoPtsAttempt: '2pts Attempt',
    threePts: '3pts',
    threePtsAttempt: '3pts Attempt',
    ft: 'Free Throw',
    ftAttempt: 'Free Throw Attempt',
    rebound: 'Rebound',
    assist: 'Assist',
    steal: 'Steal',
    block: 'Block',
    turnover: 'Turnover',
    foul: 'Foul',
  };


  const endMatchButton = async() => {
      //console.log('matchId:', match._id);
      const doc = await getMatch(match._id);
      doc.over = 1;
      await saveMatch(doc).then((result) => {
        //console.log('Match updated:', result);
        setMatch(result);
      });
  };

  const removeMatchButton = async() => {
      //console.log('matchId:', match._id);
  };

  
  const setChange = async (name, value) => {
	if(match.over) return;
    //console.log('name:', name, value);
    // //console.log('matchId:', match._id);
    
    const doc = await getMatch(match._id);
    // //console.log('doc:', doc);
	if(name == 'team' || name == 'date'){
		doc[name] = value;
	} else {
		doc[name] = parseInt(value);
	}
    //console.log('name:', name, value);
    await saveMatch(doc).then((result) => {
      //console.log('Match updated:', result);
      setMatch(result);
      //setScoreVisitors(result.score_visitors);
      //setHomeChecked(result.home);      
    });
  }

    // Start or pause the timer
    const toggleTimer = async () => {
        setIsActive(!isActive);

        if (!isActive) {
            const startTime = Date.now();
            localStorage.setItem("timer-startTime", new Date(startTime).toISOString());
            localStorage.setItem("timer-elapsedTime", time.toString()); // Save current elapsed time
            localStorage.setItem("timer-isActive", "true"); // Store that the timer is active
        } else {
            localStorage.setItem("timer-isActive", "false"); // Pause the timer
            setElapsedTime(time); // Save the time when the timer was paused
            localStorage.setItem("timer-elapsedTime", time.toString()); // Store elapsed time when paused
        }

        const doc = await getMatch(match._id);
        doc.playing_time = formatTime(time);
        await saveMatch(doc);
    };

    // Reset the timer and clear localStorage
    const resetTimer = () => {
        setIsActive(false);
        setTime(0);
        setElapsedTime(0);
        localStorage.removeItem("timer-startTime");
        localStorage.removeItem("timer-elapsedTime");
        localStorage.removeItem("timer-isActive");
    };

    // Format the time in minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const setStats = async (name) => {
	if(match.over) return;
    //console.log('name:', name);
    // //console.log('matchId:', match._id);
    
    const doc = await getMatch(match._id);
    
    switch(name) {
        case '2':
            case '2pts':
            case 'twoPts':
            //console.log('2pts - ' + match._id);
            // Mise à jour de l'entrée dans la base de données
            try {
                doc.twoPts += 1;
                doc.twoPtsAttempt += 1;
                if(doc.home == 1) {
                    doc.score_home += 2;
                } else {
                    doc.score_visitors += 2;
                }
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case '2-':
            case '2pts Attempt':
              case 'twoPtsAttempt':
            //console.log('2pts Attempt');
            try {
                doc.twoPtsAttempt += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case '3':
            case '3pts':
              case 'threePts':
            //console.log('3pts');
            try {
                doc.threePts += 1;
                doc.threePtsAttempt += 1;
                if(doc.home == 1) {
                    doc.score_home += 3;
                } else {
                    doc.score_visitors += 3;
                }
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case '3-':
            case '3pts Attempt':
              case 'threePtsAttempt':
            //console.log('3pts Attempt');
            try {
                doc.threePtsAttempt += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
              case 'ft':
            //console.log('FT');
            try {
                doc.ft += 1;
                doc.ftAttempt += 1;
                if(doc.home == 1) {
                    doc.score_home += 1;
                } else {
                    doc.score_visitors += 1;
                }
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'ft-':
            case 'FT Attempt':
              case 'ftAttempt':
            //console.log('FT Attempt');
            try {
                doc.ftAttempt += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'assist':
            case 'Assist':
            //console.log('Assist');
            try {
                doc.assist += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'block':
            case 'Block':
            //console.log('Block');
            try {
                doc.block += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'foul':
            case 'Foul':
            //console.log('Foul');
            try {
                doc.foul += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'rebound':
            case 'Rebound':
            //console.log('Rebound');
            try {
                doc.rebound += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'steal':
            case 'Steal':
            //console.log('Steal');
            try {
                doc.steal += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'turnover':
            case 'Turnover':
            //console.log('Turnover');
            try {
                doc.turnover += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'score_home_1':
            //console.log('score_home_1');
            try {
                doc.score_home += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'score_home_2':
            //console.log('score_home_2');
            try {
                doc.score_home += 2;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'score_home_3':
            //console.log('score_home_3');
            try {
                doc.score_home += 3;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'score_visitors_1':
            //console.log('score_visitors_1');
            try {
                doc.score_visitors += 1;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'score_visitors_2':
            //console.log('score_visitors_2');
            try {
                doc.score_visitors += 2;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
        case 'score_visitors_3':
            //console.log('score_visitors_3');
            try {
                doc.score_visitors += 3;
                await saveMatch(doc);
                //console.log('Match updated:', doc);
            } catch (err) {
                //console.log('Error updating match:', err);
            }
            break;
            case 'correction_score_home_1':
                //console.log('correction_score_home_1');
                try {
                    doc.score_home -= 1;
                    await saveMatch(doc);
                    //console.log('Match updated:', doc);
                } catch (err) {
                    //console.log('Error updating match:', err);
                }
                break;
            case 'correction_score_home_2':
                //console.log('correction_score_home_2');
                try {
                    doc.score_home -= 2;
                    await saveMatch(doc);
                    //console.log('Match updated:', doc);
                } catch (err) {
                    //console.log('Error updating match:', err);
                }
                break;
            case 'correction_score_home_3':
                //console.log('correction_score_home_3');
                try {
                    doc.score_home -= 3;
                    await saveMatch(doc);
                    //console.log('Match updated:', doc);
                } catch (err) {
                    //console.log('Error updating match:', err);
                }
                break;
            case 'correction_score_visitors_1':
                //console.log('correction_score_visitors_1');
                try {
                    doc.score_visitors -= 1;
                    await saveMatch(doc);
                    //console.log('Match updated:', doc);
                } catch (err) {
                    //console.log('Error updating match:', err);
                }
                break;
            case 'correction_score_visitors_2':
                //console.log('correction_score_visitors_2');
                try {
                    doc.score_visitors -= 2;
                    await saveMatch(doc);
                    //console.log('Match updated:', doc);
                } catch (err) {
                    //console.log('Error updating match:', err);
                }
                break;
            case 'correction_score_visitors_3':
                //console.log('correction_score_visitors_3');
                try {
                    doc.score_visitors -= 3;
                    await saveMatch(doc);
                    //console.log('Match updated:', doc);
                } catch (err) {
                    //console.log('Error updating match:', err);
                }
                break;

        default:
            //console.log('default');
    }        
    // mettre a jour l'input score_home et score_visitors avec les valeurs de la base de données
    try {
        document.getElementsByName('score_home')[0].value = doc.score_home;
        document.getElementsByName('score_visitors')[0].value = doc.score_visitors;
    } catch (err) {
        //console.log('Error updating match:', err);
    }
};

  
  if (!match) {
    return null; // or return a loading spinner
  }

	return (
		<Fragment>
			<PageTitle
				activeMenu={roster.firstname + ' ' + roster.lastname}
				activeMenuLink={`/roster/${rosterId}`}
				motherMenu="Dashboard"
				motherMenuLink="/dashboard"				
				// pageContent="Accordion"
			/>
			<div className="row">
				<div className="col-12">
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">
							Match
						</h4>
						<span className={match.over ? 'badge badge-rounded badge-success' : 'badge badge-rounded badge-warning'}>
							{match.over ? 'Over' : 'In progress'}
						</span>
					</div>
					<div className="card-body">
						<div className="basic-form">
							<form onSubmit={(e) => e.preventDefault()}>
									
								<div className="form-group">
									<input
										type="text"
										className="form-control input-default "
										name="team"
										value={match['team']}
										onChange={(e) => setChange(e.target.name, e.target.value)}
									/>
								</div>

								<div className="row">
									<div className="col-6">
											
										<div className="form-group">
											<div className="form-check">
												<input
													type="checkbox"
													className="form-check-input"
													id="homeCheckbox"
													name="home"
													checked={match['home'] == 1 ? true : false}
													onChange={(e) => setChange(e.target.name, e.target.checked ? 1 : 0)}
												/>
												<label className="form-check-label" htmlFor="homeCheckbox">
													Home
													<div className="small">Check if this game is at home</div>
												</label>
											</div>
										</div>

									</div>
									<div className="col-6">
											
										<div className="form-group">
											<div className="form-check">
											<input
												type="date"
												className="form-control input-default"
												name="date"
												value={format(parseISO(match['date']), 'yyyy-MM-dd')}
												onChange={(e) => setChange(e.target.name, e.target.value)}
											/>
											</div>
										</div>
										
									</div>
								</div>

								<hr/>

								{match && match.over === 0 && (
								<div
								style={{
								borderLeft: "none",
								borderRight: "none",
								marginTop: "40px",
								marginBottom: "40px",
								paddingTop: "20px",
								paddingBottom: "20px",
								}}
								>
											<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												position: "relative",
												borderRadius: "50%",
												backgroundColor: "rgba(0, 0, 0, 0.1)",
												boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
												width: '300px',
												height: '350px',
												margin: '10px auto'
											}}
											>
											{Object.entries(actionNames).map(([name, label], index) => {
												const angle = 360 / Object.keys(actionNames).length;
												return (
													<Button variant="primary"
													name={name} 
													style={{
													transform: `rotate(${angle * index}deg) translate(150px) rotate(-${(angle * index)}deg)`,
													position: 'absolute',
													top: '50%',
													left: '50%',
													width: '60px',
													height: '60px',
													margin: '-25px 0 0 -25px',
													fontSize: '10px',
													textTransform: 'uppercase',
													fontWeight: 'bold',
													padding: '0',
													}}
													key={name}
													onClick={(e) => setStats(e.target.name)} 
												>
													{label}
												</Button>
												)
											})}
											<Button
                                                variant="primary"
                                                name="time"
                                                onClick={toggleTimer}
                                                style={{
                                                    position: 'absolute',
                                                    top: '47%',
                                                    left: '47%',
                                                    width: '80px',
                                                    height: '80px',
                                                    margin: '-25px 0 0 -25px',
                                                    borderRadius: '50%',
                                                    fontSize: '10px',
                                                    textTransform: 'uppercase',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                            {formatTime(time)}
										</Button>
									</div>
								</div>
								)}


								<div className="card-header">
									<h4 className="card-title">Score</h4>
								</div>
								
								<div className="row" style={{marginTop:'20px'}}>
									<div className="form-group col-6">
										<label>Home Team</label>
										<input
										type="number"
										className="form-control"
										name={`score_home`}
										value={match['score_home']}
										onChange={(e) => setChange(e.target.name, e.target.value)}
										/>
										{match && match.over === 0 && (
											<>
											<ButtonGroup style={{marginTop:'10px', width:'100%'}}>
												<Button variant="primary" key={`score_home_1`} name={`score_home_1`} onClick={(e) => setStats(e.target.name)}>+1</Button>
												<Button variant="success" key={`score_home_2`} name={`score_home_2`} onClick={(e) => setStats(e.target.name)}>+2</Button>
												<Button variant="primary" key={`score_home_3`} name={`score_home_3`} onClick={(e) => setStats(e.target.name)}>+3</Button>
											</ButtonGroup>
											
											<ButtonGroup style={{marginTop:'10px', width:'100%'}}>
												<Button variant="outline-danger" key={`correction_score_home_1`} name={`correction_score_home_1`} onClick={(e) => setStats(e.target.name)}>-1</Button>
												<Button variant="outline-warning" key={`correction_score_home_2`} name={`correction_score_home_2`} onClick={(e) => setStats(e.target.name)}>-2</Button>
												<Button variant="outline-danger" key={`correction_score_home_3`} name={`correction_score_home_3`} onClick={(e) => setStats(e.target.name)}>-3</Button>
											</ButtonGroup>
											</>
										)}

									</div>	
									<div className="form-group col-6">
										<label>visitors Team</label>
										<input
										type="number"
										className="form-control"
										name={`score_visitors`}
										value={match['score_visitors']}
										onChange={(e) => setChange(e.target.name, e.target.value)}
										/>
										{match && match.over === 0 && (
											<>
											<ButtonGroup style={{marginTop:'10px', width:'100%'}}>
												<Button variant="primary" key={`score_visitors_1`} name={`score_visitors_1`} onClick={(e) => setStats(e.target.name)}>+1</Button>
												<Button variant="success" key={`score_visitors_2`} name={`score_visitors_2`} onClick={(e) => setStats(e.target.name)}>+2</Button>
												<Button variant="primary" key={`score_visitors_3`} name={`score_visitors_3`} onClick={(e) => setStats(e.target.name)}>+3</Button>
											</ButtonGroup>
											
											<ButtonGroup style={{marginTop:'10px', width:'100%'}}>
												<Button variant="outline-danger" key={`correction_score_visitors_1`} name={`correction_score_visitors_1`} onClick={(e) => setStats(e.target.name)}>-1</Button>
												<Button variant="outline-warning" key={`correction_score_visitors_2`} name={`correction_score_visitors_2`} onClick={(e) => setStats(e.target.name)}>-2</Button>
												<Button variant="outline-danger" key={`correction_score_visitors_3`} name={`correction_score_visitors_3`} onClick={(e) => setStats(e.target.name)}>-3</Button>
											</ButtonGroup>
											</>
										)}

									</div>	

								</div>
							


								<Accordion
									className="accordion accordion-solid-bg"
									defaultActiveKey="0"
									style={{marginTop:'40px'}}
								>
									<Accordion.Item  key="data" eventKey="data">
										<Accordion.Header  className="accordion-header accordion-header-primary">
										<span className="accordion-header-icon"></span>
										<span className="accordion-header-text">Data</span>
										<span className="accordion-header-indicator "></span>
										</Accordion.Header>
										<Accordion.Collapse eventKey="data" className="accordion__body">
											<div className="accordion-body">
												<div className="row">
													{Object.entries(actionNames).map(([name, label], index) => {
                										return (
															<div className="form-group col-6">
																<label>{label}</label>
																<input
																type="number"
																className="form-control"
																name="{name}"
																value={match[name]}
																onChange={(e) => setChange(e.target.name, e.target.value)}
																/>
															</div>
														)
													})}
												</div>
											</div>
										</Accordion.Collapse>
									</Accordion.Item >

								</Accordion>
								
							</form>
						</div>
					</div>
				</div>
				</div>
			</div>

			<div className="d-flex justify-content-center align-items-center">
				<div className="row">
					<div className="col-12">
						{match && match.over === 0 && (
							<Button variant="warning" key={`btn_end`} name={`btn_end`} onClick={endMatchButton}>
								End of the Match
							</Button>
						)}
					</div>
				</div>
			</div>
			
		</Fragment>
	);
		


};

export default Match;

import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import RostersSlider from "../Gymove/Home/RostersSlider";

import { ThemeContext } from "../../../context/ThemeContext";

import swal from "sweetalert";
import { nanoid } from "nanoid";
import DatePicker from "react-datepicker";
import user from "./../../../images/avatar/default.png";
import { date } from "yup/lib/locale";
import { saveRoster } from "../../../services/SharedFunctions";
import { useNavigate } from "react-router-dom";

import PowerBar from "../../../jsx/pages/WidgetBasic/PowerBar";
import PowerLine from "../../../jsx/pages/WidgetBasic/PowerLine";

import TopProducts1 from "../../../jsx/pages/WidgetBasic/TopProducts1";
import TopProducts2 from "../../../jsx/pages/WidgetBasic/TopProducts2";
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import avatar4 from "../../../images/avatar/4.jpg";

import { getDashboardMatches } from "../../../services/SharedFunctions";
import { getDashboardRosters } from "../../../services/SharedFunctions";


import "react-calendar/dist/Calendar.css";
import { format, parseISO } from "date-fns";

import Calendar from "react-calendar";
import Carousel from "react-bootstrap/Carousel";

import InstagramEmbed from "../../../services/InstagramEmbed";

const Dashboard = () => {
  const navigate = useNavigate();
  const [postModal, setPostModal] = useState(false);
  const [contacts, setContacts] = useState();
  const [dashboadMatches, setDashboardMatches] = useState(null);
  const [dashboardRosters, setDashboardRosters] = useState(null);

  // delete data
  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === contactId);
    newContacts.splice(index, 1);
    setContacts(newContacts);
  };

  //Add data
  const [addFormData, setAddFormData] = useState({
    Cust_Id: "",
    Date_Join: "",
    Cust_Name: "",
    Location: "",
    image: "",
  });

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Add contact function
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  //Add Submit data
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    var error = false;
    var errorMsg = "";
    if (addFormData.firstname === "") {
      error = true;
      errorMsg = "Please fill firstname.";
    } else if (addFormData.lastname === "") {
      error = true;
      errorMsg = "Please fill lastname.";
    }
    if (!error) {
      const newRoster = {
        _id: null,
        type: "roster",
        firstname: addFormData.firstname,
        lastname: addFormData.lastname,
        club: addFormData.club,
        position: addFormData.position,
        yearsOfExxperience: addFormData.yearsOfExperience,
        dateOfBirth: addFormData.dateOfBirth,
        gender: addFormData.gender,
        image: addFormData.image,
      };
      saveRoster(newRoster);
      //const newContacts = [...contacts, newContact];
      //setContacts(newContacts);
      setPostModal(false);
      swal("Good job!", "Successfully Added", "success");
      addFormData.firstname =
        addFormData.lastname =
        addFormData.club =
        addFormData.position =
        addFormData.gender =
        addFormData.dateOfBirth =
        addFormData.yearsOfExperience =
          "";
      navigate("/dashboard");
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  //For Image upload in ListBlog
  const [file, setFile] = React.useState(null);
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setTimeout(function () {
      var src = document.getElementById("saveImageFile").getAttribute("src");
      addFormData.image = src;
    }, 200);
  };

  const [valueCalendar] = useState(new Date());

  const onChangeCalendar = (value) => {
   
    getDashboardMatches("", "", value).then((result) => {
      setDashboardMatches(result);
    });
    
  };

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      //console.log("User Details ______________");
      //console.log(JSON.parse(localStorage.getItem("userDetails")).authorization.user_id);

      //var user_id = JSON.parse(localStorage.getItem("userDetails")).authorization.user_id;

      getDashboardMatches("", "", "current").then((result) => {
        setDashboardMatches(result);
      });
    }
    getDashboardRosters("").then((result) => {
      setDashboardRosters(result);
    });
  }, []);
  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-8 col-lg-12 col-xxl-8 col-sm-12">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="card text-white bg-primary">
              <Carousel>
              {dashboardRosters &&
                  dashboardRosters.map((roster) => (
                  <Carousel.Item key={roster._id}>
                      <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">
                          <strong>{roster.firstname} {roster.lastname}</strong>
                        </span>
                        <strong></strong>
                      </li>
                      <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">Nombre de joueurs actifs :</span>
                        <strong>N/A</strong>
                      </li>

                      <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">Prochain match :</span>
                        <strong>{roster.statistics.game.date}</strong>
                      </li>
                      <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">&nbsp;</span>
                        <strong>{roster.statistics.game.team}</strong>
                      </li>

                      <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">Prochain entrainement :</span>
                        <strong>{roster.statistics.training.date}</strong>
                      </li>
                      <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">&nbsp;</span>
                        <strong>{roster.statistics.training.type}</strong>
                      </li>

                      <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">Performance récente :</span>
                        <strong>{roster.statistics.performance}</strong>
                      </li>
                      
                      <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">&nbsp;</span>
                        <strong>&nbsp;</strong>
                      </li>
                    </ul>
                  </Carousel.Item>
                  ))}
              </Carousel>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="card text-white bg-warning text-black">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0"><strong>TEAMS comming soon</strong></span>
                  </li>
                  {/*
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Classement de l'équipe :</span>
                    <strong>2nd</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Statistiques clés :</span>
                    <strong>11 W/3 L</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Prochain adversaire :</span>
                    <strong>FCB </strong>
                  </li>
                */}
                </ul>
              </div>
            </div>

            {/*
            <div className="col-lg-6 col-sm-6">
              <div className="card text-white bg-info text-black">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0"><strong>SCHEDULES</strong></span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Calendrier d'entraînement :</span>
                    <strong>12/06/2024 10h00</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Objectifs du programme :</span>
                    <strong>Dribble</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Progression :</span>
                    <strong>A+</strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="card text-white bg-secondary text-black">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0"><strong>MATCHES</strong></span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Prochain match :</span>
                    <strong>28/05/2024 18h00</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Dernier match :</span>
                    <strong>07/05/2024 18h00</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Meilleurs joueurs :</span>
                    <strong>N/A</strong>
                  </li>
                </ul>
              </div>
            </div>
            */}

            {/*
            <div className="col-lg-6 col-sm-6">
              <div className="card text-white bg-success text-black">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">STATISTICS</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Leaders de statistiques :</span>
                    <strong>N/A</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Trends :</span>
                    <strong>B+</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Comparaisons :</span>
                    <strong>+3 W</strong>
                  </li>
                </ul>
              </div>
            </div>


            <div className="col-lg-6 col-sm-6">
              <div className="card pb-0">
                <div className="card-header border-0 pb-0">
                  <h4 className="text-black fs-14">
                    <strong>TIPS OF THE DAY</strong>
                  </h4>
                </div>
                <div className="card-body p-0">

                <InstagramEmbed postUrl="https://www.instagram.com/p/C5viFNrurMY/" />

                </div>
              </div>
            </div>
            */}
          </div>
        </div>

        <div className="col-xl-4 col-lg-12 col-xxl-4 col-sm-12">
          <div className="card flex-xl-column flex-md-row flex-column">
            <div className="card-body border-bottom pb-4 p-2 pt-4 event-calender">
              <Calendar onChange={onChangeCalendar} value={valueCalendar} />
            </div>
            <div className="card-body">
              <h6 className="fs-16 text-black mb-4">Schedule</h6>
              <div
                className="card-body loadmore-content height340 dz-scroll height pb-4 pt-0"
                id="recentActivitiesContent"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                {dashboadMatches &&
                  dashboadMatches.map((match) => (
                    <div className="d-flex mb-4 align-items-center" key={match._id}>
                      <span className="date-icon me-3">
                        {parseISO(match.date).getDate()}
                      </span>
                      <div>
                        <h6 className="fs-16">
                          <Link
                            to={"/roster/" + match._id + "/match/" + match._id}
                            className="text-black"
                          >
                            {match.team ? match.team.toUpperCase() : "N/A"}
                          </Link>
                        </h6>
                        <span>{match.home ? "HOME" : "AWAY"}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {" "}
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">Top Products</h4>
            </div>
            <div className="card-body pb-0">
              <div className="widget-media">
                <ul className="timeline">
                  <li>
                    <div className="timeline-panel">
                      <div className="media me-2">
                        <img alt="" width="50" src={avatar1} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1">Dr sultads Send you Photo</h5>
                        <small className="d-block">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="primary light"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item className="dropdown-item" to="#">
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown-item" to="#">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="media me-2 media-info">KG</div>
                      <div className="media-body">
                        <h5 className="mb-1">Resport created successfully</h5>
                        <small className="d-block">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="btn btn-info light sharp"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item className="dropdown-item" to="#">
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown-item" to="#">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="media me-2 media-success">
                        <i className="fa fa-home"></i>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1">Reminder : Treatment Time!</h5>
                        <small className="d-block">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="btn btn-success light sharp"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item className="dropdown-item" to="#">
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown-item" to="#">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="chart-wrapper">
              <TopProducts1 />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">Top Products</h4>
            </div>
            <div className="card-body pb-0">
              <div className="widget-media">
                <ul className="timeline">
                  <li>
                    <div className="timeline-panel">
                      <div className="media me-2">
                        <img alt="" width="50" src={avatar4} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1">Dr sultads Send you Photo</h5>
                        <small className="d-block">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="btn btn-primary light sharp"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item className="dropdown-item" to="#">
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown-item" to="#">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="media me-2 media-info">
                        <img width="50" alt="" src={avatar2} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1">Resport created successfully</h5>
                        <small className="d-block">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="btn btn-info light sharp"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item className="dropdown-item" to="#">
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown-item" to="#">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="media me-2 media-success">
                        <img width="50" alt="" src={avatar3} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1">Reminder : Treatment Time!</h5>
                        <small className="d-block">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="btn btn-success light sharp"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item className="dropdown-item" to="#">
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown-item" to="#">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="chart-wrapper">
              <TopProducts2 />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;

import React from 'react';
import { Pagination, Row, Col, Nav } from 'react-bootstrap';

const UiPagination = ({ totalItems, itemsPerPage, currentPage, onPageChange, variant, size }) => {
    // Calcul du nombre total de pages
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    let items = [];

    // Génération des numéros de page
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => onPageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    const pag = (size, gutter, variant, bg, circle) => (
        <Pagination
            size={size}
            className={`mt-4  ${gutter ? 'pagination-gutter' : ''} ${
                variant && `pagination-${variant}`
            } ${!bg && 'no-bg'} ${circle && 'pagination-circle'}`}
        >
            <li className="page-item page-indicator">
                <button
                    className="page-link"
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <i className="la la-angle-left" />
                </button>
            </li>
            {items}
            <li className="page-item page-indicator">
                <button
                    className="page-link"
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <i className="la la-angle-right" />
                </button>
            </li>
        </Pagination>
    );

    return (
        <Row>
            <Col xl={6} className="col-lg-6">
                <Nav>{pag(size, false, variant, true, false)}</Nav>
            </Col>
        </Row>
    );
};

export default UiPagination;
import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import axios from "axios";

// Fonction pour mettre à jour les informations utilisateur avec Axios
const updateUserInfo = async (updatedInfo, token) => {
	try {
		const response = await axios.put(
			`${process.env.REACT_APP_API_URL}/user/update`,
			updatedInfo,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		alert("User information updated successfully");

		return response.data;
	} catch (error) {
		console.error("Error updating user:", error);
		alert("An error occurred while updating user information.");
	}
};

const AppProfile = () => {
	const userDetails = JSON.parse(localStorage.getItem("userDetails"));
	const token = userDetails?.authorization?.access_token;

	const [userInfo, setUserInfo] = useState({
		name: userDetails?.authorization?.user_name || "",
		email: userDetails?.authorization?.user_email || "",
	});

	const [passwords, setPasswords] = useState({
		currentPassword: "",
		newPassword: "",
		confirmNewPassword: "",
	});

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// Gère la modification des informations utilisateur
	const handleUserInfoChange = (e) => {
		const { name, value } = e.target;
		setUserInfo({ ...userInfo, [name]: value });
	};

	// Gère la modification des mots de passe
	const handlePasswordChange = (e) => {
		const { name, value } = e.target;
		setPasswords({ ...passwords, [name]: value });
	};

	// Soumet les nouvelles informations utilisateur
	const handleUserInfoSubmit = (e) => {
		e.preventDefault();
		const updatedInfo = {
			user_id: userDetails.authorization.user_id,
			user_name: userInfo.name,
			user_email: userInfo.email,
		};

		// Met à jour les informations utilisateur via l'API avec Axios
		updateUserInfo(updatedInfo, token);
	};

	// Soumet le nouveau mot de passe
	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		if (passwords.newPassword !== passwords.confirmNewPassword) {
			alert("New passwords do not match!");
		} else {
			// Ajouter la logique pour changer le mot de passe via l'API avec Axios
			alert("Password successfully updated!");
		}
	};

	// Gère la suppression du compte utilisateur
	const handleDeleteAccount = () => {
		setShowDeleteModal(false);
		alert("Account successfully deleted!");
		// Ajouter la logique de suppression du compte via Axios
	};

	return (
		<div className="container mt-5">
			<h2 className="text-center mb-4">User Profile</h2>

			{/* Section de mise à jour des informations utilisateur */}
			<Form onSubmit={handleUserInfoSubmit}>
				<h4>Update Personal Information</h4>
				<Row>
					<Col md={6}>
						<Form.Group controlId="formName">
							<Form.Label>Full Name</Form.Label>
							<Form.Control
								type="text"
								name="name"
								value={userInfo.name}
								onChange={handleUserInfoChange}
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId="formEmail">
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type="email"
								name="email"
								value={userInfo.email}
								onChange={handleUserInfoChange}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Button className="mt-3" variant="primary" type="submit">
					Update Information
				</Button>
			</Form>

			<hr className="my-4" />

			{/* Section de mise à jour du mot de passe */}
			<Form onSubmit={handlePasswordSubmit}>
				<h4>Change Password</h4>
				<Row>
					<Col md={4}>
						<Form.Group controlId="formCurrentPassword">
							<Form.Label>Current Password</Form.Label>
							<Form.Control
								type="password"
								name="currentPassword"
								value={passwords.currentPassword}
								onChange={handlePasswordChange}
							/>
						</Form.Group>
					</Col>
					<Col md={4}>
						<Form.Group controlId="formNewPassword">
							<Form.Label>New Password</Form.Label>
							<Form.Control
								type="password"
								name="newPassword"
								value={passwords.newPassword}
								onChange={handlePasswordChange}
							/>
						</Form.Group>
					</Col>
					<Col md={4}>
						<Form.Group controlId="formConfirmNewPassword">
							<Form.Label>Confirm New Password</Form.Label>
							<Form.Control
								type="password"
								name="confirmNewPassword"
								value={passwords.confirmNewPassword}
								onChange={handlePasswordChange}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Button className="mt-3" variant="primary" type="submit">
					Update Password
				</Button>
			</Form>

			<hr className="my-4" />

			{/* Zone dangereuse pour supprimer le compte */}
			<h4>Danger Zone</h4>
			<Button
				className="mt-3"
				variant="danger"
				onClick={() => setShowDeleteModal(true)}
			>
				Delete Account
			</Button>

			{/* Modale de confirmation de suppression */}
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Account Deletion</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete your account? This action cannot be undone.
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
						Cancel
					</Button>
					<Button variant="danger" onClick={handleDeleteAccount}>
						Delete Account
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default AppProfile;
import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { format, parseISO } from "date-fns";
// import styles

import { useRoster } from "../../../context/RosterContext";

import { getRosterMatches } from "../../../services/SharedFunctions";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Table } from "react-bootstrap";

const UpcomingMatches = () => {
  const { rosters, selectedRoster, setSelectedRoster } = useRoster();

  const [matches, setMatches] = useState(null);

  useEffect(() => {
    getRosterMatches(selectedRoster._id, "previous").then((result) => {
      setMatches(result);
    });
  }, [selectedRoster]);

  const navigate = useNavigate();

  if (!selectedRoster) {
    return null; // or return a loading spinner
  }

  return (
    <Fragment>
      <PageTitle
        activeMenu="Previous Games"
        motherMenu={selectedRoster.firstname + " " + selectedRoster.lastname}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="table-container">
                <div className="fixed-column">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>MATCH UP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matches &&
                        matches.map((match) => (
                          <tr key={match.id}>
                            <td>
                              {match.date
                                ? format(parseISO(match.date), "dd/MM/yyyy")
                                : "N/A"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className="scrollable-content">
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th></th>
                        <th>W/L</th>
                        <th>SCORE</th>
                        <th>MIN</th>
                        <th>PTS</th>
                        <th>FGM</th>
                        <th>FGA</th>
                        <th>FG%</th>
                        <th>3PM</th>
                        <th>3PA</th>
                        <th>3P%</th>
                        <th>FTM</th>
                        <th>FTA</th>
                        <th>FT%</th>
                        <th>OREB</th>
                        <th>DREB</th>
                        <th>REB</th>
                        <th>AST</th>
                        <th>STL</th>
                        <th>BLK</th>
                        <th>TOV</th>
                        <th>PF</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matches &&
                        matches.map((match) => (
                          <tr key={match.id}>
                            <td>
                              {match.team.toUpperCase()}
                              {" @ "}
                              {match.home ? "HOME" : "AWAY"}
                            </td>
                            <td>
                              {match.home >= 0 && match.score_visitors >= 0
                                ? (match.home &&
                                    match.score_home > match.score_visitors) ||
                                  (match.home === 0 &&
                                    match.score_visitors > match.score_home)
                                  ? "W"
                                  : "L"
                                : ""}
                            </td>
                            <td>
                              {match.score_home && match.score_visitors
                                ? match.score_home + ":" + match.score_visitors
                                : "0:0"}
                            </td>
                            <td> {match.playing_time}</td>
                            <td>
                              {match.twoPts * 2 + match.threePts * 3 + match.ft}
                            </td>
                            <td>{match.twoPts + match.threePts}</td>
                            <td>
                              {match.twoPtsAttempt + match.threePtsAttempt}
                            </td>
                            <td>
                              {isNaN(
                                (match.twoPts + match.threePts) /
                                  (match.twoPtsAttempt + match.threePtsAttempt)
                              )
                                ? "0.0"
                                : ((match.twoPts + match.threePts) /
                                    (match.twoPtsAttempt +
                                      match.threePtsAttempt)) *
                                    100 ==
                                  100
                                ? "100"
                                : (
                                    ((match.twoPts + match.threePts) /
                                      (match.twoPtsAttempt +
                                        match.threePtsAttempt)) *
                                    100
                                  ).toFixed(1)}
                            </td>
                            <td>{match.threePts}</td>
                            <td>{match.threePtsAttempt}</td>
                            <td>
                              {isNaN(match.threePts / match.threePtsAttempt)
                                ? "0.0"
                                : (match.threePts / match.threePtsAttempt) *
                                    100 ==
                                  100
                                ? "100"
                                : (
                                    (match.threePts / match.threePtsAttempt) *
                                    100
                                  ).toFixed(1)}
                            </td>
                            <td>{match.ft}</td>
                            <td>{match.ftAttempt}</td>
                            <td>
                              {isNaN(match.ft / match.ftAttempt)
                                ? "0.0"
                                : (match.ft / match.ftAttempt) * 100 == 100
                                ? "100"
                                : ((match.ft / match.ftAttempt) * 100).toFixed(
                                    1
                                  )}
                            </td>
                            <td>{match.orebound}</td>
                            <td>{match.drebound}</td>
                            <td>{match.rebound}</td>
                            <td>{match.assist}</td>
                            <td>{match.steal}</td>
                            <td>{match.block}</td>
                            <td>{match.turnover}</td>
                            <td>{match.foul}</td>

                            <td>
                              <span
                                className={
                                  match.over
                                    ? "badge badge-rounded badge-success"
                                    : "badge badge-rounded badge-warning"
                                }
                              >
                                {match.over ? "Finished" : "Unfinished"}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="card-body">
                <Row>
                  <Col lg={4} md={6}>
                    <div>
                      <b>W/L</b> - Win/Loss
                    </div>
                    <div>
                      <b>MIN</b> - Minutes Played
                    </div>
                    <div>
                      <b>PTS</b> - Points
                    </div>
                    <div>
                      <b>FGM</b> - Field Goals Made
                    </div>
                    <div>
                      <b>FGA</b> - Field Goals Attempted
                    </div>
                    <div>
                      <b>FG%</b> - Field Goal Percentage
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div>
                      <b>3PM</b> - 3-Point Field Goals Made
                    </div>
                    <div>
                      <b>3PA</b> - 3-Point Field Goals Attempted
                    </div>
                    <div>
                      <b>3P%</b> - 3-Point Field Goal Percentage
                    </div>
                    <div>
                      <b>FTM</b> - Free Throws Made
                    </div>
                    <div>
                      <b>FTA</b> - Free Throws Attempted
                    </div>
                    <div>
                      <b>FT%</b> - Free Throw Percentage
                    </div>
                  </Col>
                  <Col lg={4} md={12}>
                    <div>
                      <b>OREB</b> - Offensive Rebounds
                    </div>
                    <div>
                      <b>DREB</b> - Defensive Rebounds
                    </div>
                    <div>
                      <b>REB</b> - Rebounds
                    </div>
                    <div>
                      <b>AST</b> - Assists
                    </div>
                    <div>
                      <b>STL</b> - Steals
                    </div>
                    <div>
                      <b>BLK</b> - Blocks
                    </div>
                    <div>
                      <b>TOV</b> - Turnovers
                    </div>
                    <div>
                      <b>PF</b> - Personal Fouls
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UpcomingMatches;

import React, { useState, useEffect, Fragment } from "react";
import { useRoster } from "../../context/RosterContext";
import { Card } from "react-bootstrap";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import user from "../../images/avatar/default.png";
import { getRoster, saveRoster } from "../../services/SharedFunctions";
import swal from "sweetalert";
import { Row, Col, Accordion } from "react-bootstrap";

const defaultAccordion = [
  {
    title: "Tips for Setting Objectives",
    text: "",
    bg: "primary",
  },
];

const BodyMetrics = () => {
  const { selectedRoster, setSelectedRoster, rosters } = useRoster();
  const rosterId = selectedRoster ? selectedRoster._id : null;

  //update data function
  const handleEditFormChange = (event) => {
    //console.log(event.target.name, event.target.value);
    const { name, value } = event.target;
    setSelectedRoster((prevRoster) => ({
      ...prevRoster,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setSelectedRoster((prevRoster) => ({
      ...prevRoster,
      dateOfBirth: date,
    }));
  };

  const handleTargetEditFormChange = (event) => {
    const { name, value } = event.target;
    setSelectedRoster((prevRoster) => ({
      ...prevRoster,
      target: {
        ...prevRoster.target,
        [name]: parseInt(value),
      },
    }));
  };

  const handleProfileVisibilityChange = (isChecked) => {
    setSelectedRoster({
      ...selectedRoster,
      profileVisibility: isChecked ? "public" : "private",
    });
  };

  //For Image upload in ListBlog
  const [file, setFile] = React.useState(null);
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setTimeout(function () {
      var src = document.getElementById("saveImageFile").getAttribute("src");
      //addFormData.image = src;
    }, 200);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      _id: rosterId,
      firstname: selectedRoster.firstname,
      lastname: selectedRoster.lastname,
      club: selectedRoster.club,
      gender: selectedRoster.gender,
      position: selectedRoster.position,
      yearsOfExperience: selectedRoster.yearsOfExperience,
      dateOfBirth: selectedRoster.dateOfBirth,
      image: selectedRoster.image,
      target: selectedRoster.target,
      privacy: selectedRoster.privacy,
    };

    getRoster(rosterId).then((result) => {
      result.firstname = editedContact.firstname;
      result.lastname = editedContact.lastname;
      result.club = editedContact.club;
      result.gender = editedContact.gender;
      result.position = editedContact.position;
      result.yearsOfExperience = editedContact.yearsOfExperience;
      result.dateOfBirth = editedContact.dateOfBirth;
      result.image = editedContact.image;
      result.target = editedContact.target;
      result.privacy = editedContact.privacy;
      setSelectedRoster(result);

      saveRoster(result);
      swal("Good job!", "Roster has been saved", "success");
    });
  };

  if (!selectedRoster) {
    return null; // or return a loading spinner
  }

  return (
    <>
      <Fragment>
        <div className="row">
          <div className="col"></div>
          <div
            className="col-xl-8 center-content"
            style={{ maxWidth: "800px" }}
          >
            <div className="alert alert-info mt-5" role="alert">
              <h4 className="alert-heading">Exciting Update Coming Soon!</h4>
              <p>
                We're thrilled to announce that a personalized training plan
                based on your seasonal goals will soon be available! This
                tailored plan will help you focus on the areas that need the
                most improvement, ensuring that you make the most out of every
                training session.
              </p>
              <hr />
              <p className="mb-0">
                Stay tuned for more details on how to access your customized
                training regimen and start achieving your best performance yet!
              </p>
            </div>
          </div>
          <div className="col"></div>
        </div>
      </Fragment>
    </>
  );
};

export default BodyMetrics;

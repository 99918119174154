import React, { Fragment, useReducer, useState, useEffect } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import LightGallery from "lightgallery/react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

//import PageTitle from "../../layouts/PageTitle";

import {
  getRoster,
  getRosterMatches,
  saveRoster,
} from "../../../services/SharedFunctions";
import { useNavigate } from "react-router-dom";
import avatar from "../../../images/avatar/default.png";
import user from "./../../../images/avatar/default.png";
import DatePicker from "react-datepicker";
import { date } from "yup/lib/locale";
import { Row, Col, Card } from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const initialState = false;
const reducer = (state, action) => {
  switch (action.type) {
    case "sendMessage":
      return { ...state, sendMessage: !state.sendMessage };
    case "postModal":
      return { ...state, post: !state.post };
    case "linkModal":
      return { ...state, link: !state.link };
    case "cameraModal":
      return { ...state, camera: !state.camera };
    case "replyModal":
      return { ...state, reply: !state.reply };
    default:
      return state;
  }
};

const Profile = () => {
  const { rosterId } = useParams();
  const [roster, setRoster] = useState(null);
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    getRoster(rosterId).then((result) => {
      setRoster(result);
      setEditFormData(result);
      //console.log(result);
    });
    getRosterMatches(rosterId).then((result) => {
      setMatches(result);
    });
  }, []);

  function calculateTotals(matches) {
    let totalPoints = 0;
    let totalAssists = 0;
    let totalBlocks = 0;
    let totalRebounds = 0;
    if (matches) {
      matches.forEach((match) => {
        totalPoints += match.twoPts * 2 + match.threePts * 3 + match.ft;
        totalAssists += match.assist;
        totalBlocks += match.block;
        totalRebounds += match.rebound;
      });
    }

    return { totalPoints, totalAssists, totalBlocks, totalRebounds };
  }
  const { totalPoints, totalAssists, totalBlocks, totalRebounds } =
    calculateTotals(matches);

  const matchsDate = matches
    ? matches.map((match) =>
        match.date ? format(parseISO(match.date), "dd/MM/yyyy") : ""
      )
    : [];
  const matchsDatasetEvolution = matches
    ? [
        {
          name: "2pts",
          data: matches.map((match) => match.twoPts),
          fontSize: 12,
        },
        {
          name: "3pts",
          data: matches.map((match) => match.threePts),
        },
        {
          name: "LF",
          data: matches.map((match) => match.ft),
        },
        {
          name: "Assist",
          data: matches.map((match) => match.assist),
        },
        {
          name: "Block",
          data: matches.map((match) => match.block),
        },
        {
          name: "Foul",
          data: matches.map((match) => match.foul),
        },
        {
          name: "Rebound",
          data: matches.map((match) => match.rebound),
        },
        {
          name: "Steal",
          data: matches.map((match) => match.steal),
        },
        {
          name: "Turnover",
          data: matches.map((match) => match.turnover),
        },
      ]
    : [];

  const navigate = useNavigate();

  const voirDetailsMatch = (matchId) => {
    navigate(`/roster/${rosterId}/match/${matchId}`);
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const [editModal, setEditModal] = useState(false);

  // Edit function editable page loop
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact._id);
    const formValues = {
      _id: contact._id,
      firstname: contact.firstname,
      lastname: contact.lastname,
      club: contact.club,
      gender: contact.gender,
      position: contact.position,
      yearsOfExperience: contact.yearsOfExperience,
      dateOfBirth: contact.dateOfBirth,
      image: contact.image,
    };
    setEditFormData(contact);
    setEditModal(true);
  };

  // edit  data
  const [editFormData, setEditFormData] = useState({
    _id: "",
    firstname: "",
    lastname: "",
    club: "",
    gender: "",
    position: "",
    yearsOfExperience: "",
    dateOfBirth: "",
    image: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      _id: editContactId,
      firstname: editFormData.firstname,
      lastname: editFormData.lastname,
      club: editFormData.club,
      gender: editFormData.gender,
      position: editFormData.position,
      yearsOfExperience: editFormData.yearsOfExperience,
      dateOfBirth: editFormData.dateOfBirth,
      image: editFormData.image,
    };
    setEditContactId(null);

    // update roster
    roster.firstname = editedContact.firstname;
    roster.lastname = editedContact.lastname;
    roster.club = editedContact.club;
    roster.gender = editedContact.gender;
    roster.position = editedContact.position;
    roster.yearsOfExperience = editedContact.yearsOfExperience;
    roster.dateOfBirth = editedContact.dateOfBirth;
    roster.image = editedContact.image;

    setRoster(roster);
    saveRoster(roster);

    setEditModal(false);
  };

  //For Image upload in ListBlog
  const [file, setFile] = React.useState(null);
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setTimeout(function () {
      var src = document.getElementById("saveImageFile").getAttribute("src");
      //addFormData.image = src;
    }, 200);
  };

  if (!roster) {
    return null; // or return a loading spinner
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={roster.image ? roster.image : avatar}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {roster.firstname} {roster.lastname}
                    </h4>
                    <p>{roster.club}</p>
                  </div>
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={(event) => handleEditClick(event, roster)}
                      >
                        <i className="fa fa-user-circle text-primary me-2" />
                        Edit Roster
                      </Dropdown.Item>
                      {/*
						  <Dropdown.Item className="dropdown-item">
							<i className="fa fa-users text-primary me-2" />
							Add to close friends
						  </Dropdown.Item>
						  */}
                      <Dropdown.Item
                        className="dropdown-item"
                        href={"/roster/" + rosterId + "/match"}
                      >
                        <i className="fa fa-plus text-primary me-2" />
                        Add match
                      </Dropdown.Item>
                      {/*
						  <Dropdown.Item className="dropdown-item text-danger">
							<i className="fa fa-ban text-danger me-2" />
							Block
						  </Dropdown.Item>
						  */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-statistics">
                <div className="text-center">
                  <div className="row">
                    <div className="col">
                      <h3 className="m-b-0">{totalPoints}</h3>
                      <span>Points</span>
                    </div>
                    <div className="col">
                      <h3 className="m-b-0">{totalAssists}</h3>{" "}
                      <span>Assists</span>
                    </div>
                    <div className="col">
                      <h3 className="m-b-0">{totalBlocks}</h3>{" "}
                      <span>Blocks</span>
                    </div>
                    <div className="col">
                      <h3 className="m-b-0">{totalRebounds}</h3>{" "}
                      <span>Rebounds</span>
                    </div>
                  </div>
                  {/*
								<div className="mt-4">
									<Link	to="/post-details"	className="btn btn-primary mb-1 me-1">Follow</Link>
									<Button  className="btn btn-primary mb-1 ms-1" onClick={() => dispatch({type:'sendMessage'})}>Send Message</Button>
								</div>
								*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12"></div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey="Matches">
                    <Nav as="ul" className="nav nav-tabs">
                      <Nav.Item as="li" className="nav-item" key="Matches">
                        <Nav.Link to="#matches" eventKey="Matches">
                          Matches
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item" key="Charts">
                        <Nav.Link to="#charts" eventKey="Charts">
                          Charts
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item" key="NextMatches">
                        <Nav.Link to="#next-matches" eventKey="NextMatches">
                          Next Matches
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane id="matches" key="matches" eventKey="Matches">
                        <div className="pt-3">
                          <div className="table-container">
                            <div className="fixed-column MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineNone MuiTypography-root MuiTypography-button css-fy2owk-MuiTypography-root-MuiLink-root-MuiTypography-root">
                              <table style={{ fontSize: "14px" }}>
                                <tr>
                                  <th>Date</th>
                                </tr>
                                {matches &&
                                  matches.map((match) => (
                                    <tr
                                      key={match.id}
                                      onClick={() =>
                                        voirDetailsMatch(match._id)
                                      }
                                    >
                                      <td>
                                        {match.date
                                          ? format(
                                              parseISO(match.date),
                                              "dd/MM/yyyy"
                                            )
                                          : "N/A"}
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                            <div className="scrollable-content MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineNone MuiTypography-root MuiTypography-button css-fy2owk-MuiTypography-root-MuiLink-root-MuiTypography-root">
                              <table style={{ fontSize: "14px" }}>
                                <tr>
                                  <th>Adversaire</th>
                                  <th>Lieu</th>
                                  <th>Score</th>
                                  <th>2pts</th>
                                  <th>3pts</th>
                                  <th>LF</th>
                                  <th>Assist</th>
                                  <th>Block</th>
                                  <th>Foul</th>
                                  <th>Rebound</th>
                                  <th>Steal</th>
                                  <th>Turnover</th>
                                </tr>
                                {matches &&
                                  matches.map((match) => (
                                    <tr
                                      key={match.id}
                                      onClick={() =>
                                        voirDetailsMatch(match._id)
                                      }
                                    >
                                      <td>{match.team}</td>
                                      <td
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {match.home ? "HOME" : "AWAY"}
                                      </td>
                                      <td>
                                        <span
                                          className={
                                            (match.home &&
                                              match.score_home >
                                                match.score_visitors) ||
                                            (match.home === 0 &&
                                              match.score_visitors >
                                                match.score_home)
                                              ? "badge badge-rounded badge-success"
                                              : "badge badge-rounded badge-danger"
                                          }
                                        >
                                          {match.score_home &&
                                          match.score_visitors
                                            ? match.score_home +
                                              ":" +
                                              match.score_visitors
                                            : "0:0"}
                                        </span>
                                        {/*
                                        <MKBadge badgeContent={match.score_home+':'+match.score_visitors} size="xs" container color={((match.home && match.score_home > match.score_visitors) || (match.home === 0 && match.score_visitors > match.score_home)) ? 'success' : 'error'} />
                                        */}
                                      </td>
                                      <td>
                                        {match.twoPts} / {match.twoPtsAttempt}
                                      </td>
                                      <td>
                                        {match.threePts} /{" "}
                                        {match.threePtsAttempt}
                                      </td>
                                      <td>
                                        {match.ft} / {match.ftAttempt}
                                      </td>
                                      <td>{match.assist}</td>
                                      <td>{match.block}</td>
                                      <td>{match.foul}</td>
                                      <td>{match.rebound}</td>
                                      <td>{match.steal}</td>
                                      <td>{match.turnover}</td>
                                      <td>
                                        <span
                                          className={
                                            match.over
                                              ? "badge badge-rounded badge-success"
                                              : "badge badge-rounded badge-warning"
                                          }
                                        >
                                          {match.over ? "Over" : "In progress"}
                                        </span>
                                        {/*
														<MKBadge badgeContent={match.over ? 'Over' : 'In progress'} size="xs" container color={match.over ? 'success' : 'warning'} />
														*/}
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                          </div>
                        </div>

                        <Modal
                          className="modal fade"
                          show={editModal}
                          onHide={setEditModal}
                        >
                          <div className="" role="document">
                            <div className="">
                              <form>
                                <div className="modal-header">
                                  <h4 className="modal-title fs-20">
                                    Edit Roster
                                  </h4>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setEditModal(false)}
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <i className="flaticon-cancel-12 close"></i>
                                  <div className="add-contact-box">
                                    <div className="add-contact-content">
                                      <div className="image-placeholder">
                                        <div className="avatar-edit">
                                          <input
                                            type="file"
                                            onChange={fileHandler}
                                            id="imageUpload"
                                            onClick={(event) =>
                                              setFile(event.target.value)
                                            }
                                          />
                                          <label
                                            htmlFor="imageUpload"
                                            name=""
                                          ></label>
                                        </div>
                                        <div className="avatar-preview">
                                          <div id="imagePreview">
                                            <img
                                              id="saveImageFile"
                                              src={
                                                file
                                                  ? URL.createObjectURL(file)
                                                  : user
                                              }
                                              alt={file ? file.name : null}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Firstname
                                        </label>
                                        <div className="contact-name">
                                          <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="firstname"
                                            required="required"
                                            value={editFormData.firstname}
                                            onChange={handleEditFormChange}
                                            placeholder="Firstname"
                                          />
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>
                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Lastname
                                        </label>
                                        <div className="contact-name">
                                          <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="lastname"
                                            required="required"
                                            value={editFormData.lastname}
                                            onChange={handleEditFormChange}
                                            placeholder="Lastname"
                                          />
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>
                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Club
                                        </label>
                                        <div className="contact-name">
                                          <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="club"
                                            value={editFormData.club}
                                            onChange={handleEditFormChange}
                                            placeholder="Club"
                                          />
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>

                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Gender
                                        </label>
                                        <div className="contact-name">
                                          <select
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="gender"
                                            onChange={handleEditFormChange}
                                          >
                                            <option value="">
                                              Select gender
                                            </option>
                                            <option value="male">Male</option>
                                            <option value="female">
                                              Female
                                            </option>
                                          </select>
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>
                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Date of birth
                                        </label>
                                        <div className="contact-name">
                                          <DatePicker
                                            className="form-control"
                                            placeholderText="Date of birth"
                                          />
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>

                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Position
                                        </label>
                                        <div className="contact-name">
                                          <select
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="position"
                                            onChange={handleEditFormChange}
                                          >
                                            <option value="">
                                              Select Position
                                            </option>
                                            <option value="PG">
                                              Point Guard
                                            </option>
                                            <option value="SG">
                                              Shooting Guard
                                            </option>
                                            <option value="SF">
                                              Small Forward
                                            </option>
                                            <option value="PF">
                                              Power Forward
                                            </option>
                                            <option value="C">Center</option>
                                          </select>
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>

                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Years of experience
                                        </label>
                                        <div className="contact-occupation">
                                          <input
                                            type="number"
                                            autoComplete="off"
                                            name="yearsOfExperience"
                                            value={
                                              editFormData.yearsOfExperience
                                            }
                                            onChange={handleEditFormChange}
                                            className="form-control"
                                            placeholder="Years of experience"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleEditFormSubmit}
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => setEditModal(false)}
                                    className="btn btn-danger"
                                  >
                                    {" "}
                                    <i className="flaticon-delete-1" /> Discard
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </Modal>
                      </Tab.Pane>
                      <Tab.Pane id="charts" eventKey="Charts">
                        <div className="pt-3">
                          <Row>
                            <Col xl={12}>
                              <div id="chart">
                                <ReactApexChart
                                  options={{
                                    chart: {
                                      height: 350,
                                      type: "line",
                                      toolbar: {
                                        show: false,
                                      },
                                    },
                                    dataLabels: {
                                      enabled: false,
                                    },

                                    stroke: {
                                      width: [4, 4, 4],
                                      colors: ["#082073", "#21b830", "#FF9432"],
                                      curve: "straight",
                                    },
                                    legend: {
                                      show: false,
                                    },
                                    xaxis: {
                                      labels: {
                                        style: {
                                          colors: "#b3b3b3",
                                        },
                                      },
                                      axisBorder: {
                                        show: false,
                                      },
                                      axisTicks: {
                                        show: false,
                                      },
                                      type: "text",
                                      categories: matchsDate,
                                    },
                                    colors: ["#082073", "#21b830", "#FF9432"],

                                    markers: {
                                      size: [8, 8, 6],
                                      strokeWidth: [0, 0, 4],
                                      strokeColors: [
                                        "#082073",
                                        "#21b830",
                                        "#FF9432",
                                      ],
                                      border: 0,
                                      colors: ["#082073", "#21b830", "#fff"],
                                      hover: {
                                        size: 10,
                                      },
                                    },
                                    yaxis: {
                                      labels: {
                                        style: {
                                          colors: "#b3b3b3",
                                        },
                                      },
                                      title: {
                                        text: "",
                                      },
                                    },
                                    grid: {
                                      yaxis: {
                                        lines: {
                                          show: false,
                                        },
                                      },
                                    },
                                  }}
                                  series={matchsDatasetEvolution}
                                  type="line"
                                  height={350}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane id="next-matches" eventKey="NextMatches">
                        <div className="pt-3">
                          <div className="table-container">
                            <div className="fixed-column MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineNone MuiTypography-root MuiTypography-button css-fy2owk-MuiTypography-root-MuiLink-root-MuiTypography-root">
                              <table style={{ fontSize: "14px" }}>
                                <tr>
                                  <th>Date</th>
                                </tr>
                                {matches &&
                                  matches.map((match) => (
                                    <tr
                                      key={match.id}
                                      onClick={() =>
                                        voirDetailsMatch(match._id)
                                      }
                                    >
                                      <td>
                                        {match.date
                                          ? format(
                                              parseISO(match.date),
                                              "dd/MM/yyyy"
                                            )
                                          : "N/A"}
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                            <div className="scrollable-content MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineNone MuiTypography-root MuiTypography-button css-fy2owk-MuiTypography-root-MuiLink-root-MuiTypography-root">
                              <table style={{ fontSize: "14px" }}>
                                <tr>
                                  <th>Adversaire</th>
                                  <th>Lieu</th>
                                  <th>Score</th>
                                  <th>2pts</th>
                                  <th>3pts</th>
                                  <th>LF</th>
                                  <th>Assist</th>
                                  <th>Block</th>
                                  <th>Foul</th>
                                  <th>Rebound</th>
                                  <th>Steal</th>
                                  <th>Turnover</th>
                                </tr>
                                {matches &&
                                  matches.map((match) => (
                                    <tr
                                      key={match.id}
                                      onClick={() =>
                                        voirDetailsMatch(match._id)
                                      }
                                    >
                                      <td>{match.team}</td>
                                      <td
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {match.home ? "HOME" : "AWAY"}
                                      </td>
                                      <td>
                                        <span
                                          className={
                                            (match.home &&
                                              match.score_home >
                                                match.score_visitors) ||
                                            (match.home === 0 &&
                                              match.score_visitors >
                                                match.score_home)
                                              ? "badge badge-rounded badge-success"
                                              : "badge badge-rounded badge-danger"
                                          }
                                        >
                                          {match.score_home &&
                                          match.score_visitors
                                            ? match.score_home +
                                              ":" +
                                              match.score_visitors
                                            : "0:0"}
                                        </span>
                                        {/*
														<MKBadge badgeContent={match.score_home+':'+match.score_visitors} size="xs" container color={((match.home && match.score_home > match.score_visitors) || (match.home === 0 && match.score_visitors > match.score_home)) ? 'success' : 'error'} />
														*/}
                                      </td>
                                      <td>
                                        {match.twoPts} / {match.twoPtsAttempt}
                                      </td>
                                      <td>
                                        {match.threePts} /{" "}
                                        {match.threePtsAttempt}
                                      </td>
                                      <td>
                                        {match.ft} / {match.ftAttempt}
                                      </td>
                                      <td>{match.assist}</td>
                                      <td>{match.block}</td>
                                      <td>{match.foul}</td>
                                      <td>{match.rebound}</td>
                                      <td>{match.steal}</td>
                                      <td>{match.turnover}</td>
                                      <td>
                                        <span
                                          className={
                                            match.over
                                              ? "badge badge-rounded badge-success"
                                              : "badge badge-rounded badge-warning"
                                          }
                                        >
                                          {match.over ? "Over" : "In progress"}
                                        </span>
                                        {/*
														<MKBadge badgeContent={match.over ? 'Over' : 'In progress'} size="xs" container color={match.over ? 'success' : 'warning'} />
														*/}
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                          </div>
                        </div>

                        <Modal
                          className="modal fade"
                          show={editModal}
                          onHide={setEditModal}
                        >
                          <div className="" role="document">
                            <div className="">
                              <form>
                                <div className="modal-header">
                                  <h4 className="modal-title fs-20">
                                    Edit Roster
                                  </h4>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setEditModal(false)}
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <i className="flaticon-cancel-12 close"></i>
                                  <div className="add-contact-box">
                                    <div className="add-contact-content">
                                      <div className="image-placeholder">
                                        <div className="avatar-edit">
                                          <input
                                            type="file"
                                            onChange={fileHandler}
                                            id="imageUpload"
                                            onClick={(event) =>
                                              setFile(event.target.value)
                                            }
                                          />
                                          <label
                                            htmlFor="imageUpload"
                                            name=""
                                          ></label>
                                        </div>
                                        <div className="avatar-preview">
                                          <div id="imagePreview">
                                            <img
                                              id="saveImageFile"
                                              src={
                                                file
                                                  ? URL.createObjectURL(file)
                                                  : user
                                              }
                                              alt={file ? file.name : null}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Firstname
                                        </label>
                                        <div className="contact-name">
                                          <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="firstname"
                                            required="required"
                                            value={editFormData.firstname}
                                            onChange={handleEditFormChange}
                                            placeholder="Firstname"
                                          />
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>
                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Lastname
                                        </label>
                                        <div className="contact-name">
                                          <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="lastname"
                                            required="required"
                                            value={editFormData.lastname}
                                            onChange={handleEditFormChange}
                                            placeholder="Lastname"
                                          />
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>
                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Club
                                        </label>
                                        <div className="contact-name">
                                          <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="club"
                                            value={editFormData.club}
                                            onChange={handleEditFormChange}
                                            placeholder="Club"
                                          />
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>

                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Gender
                                        </label>
                                        <div className="contact-name">
                                          <select
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="gender"
                                            onChange={handleEditFormChange}
                                          >
                                            <option value="">
                                              Select gender
                                            </option>
                                            <option value="male">Male</option>
                                            <option value="female">
                                              Female
                                            </option>
                                          </select>
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>
                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Date of birth
                                        </label>
                                        <div className="contact-name">
                                          <DatePicker
                                            className="form-control"
                                            placeholderText="Date of birth"
                                          />
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>

                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Position
                                        </label>
                                        <div className="contact-name">
                                          <select
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name="position"
                                            onChange={handleEditFormChange}
                                          >
                                            <option value="">
                                              Select Position
                                            </option>
                                            <option value="PG">
                                              Point Guard
                                            </option>
                                            <option value="SG">
                                              Shooting Guard
                                            </option>
                                            <option value="SF">
                                              Small Forward
                                            </option>
                                            <option value="PF">
                                              Power Forward
                                            </option>
                                            <option value="C">Center</option>
                                          </select>
                                          <span className="validation-text"></span>
                                        </div>
                                      </div>

                                      <div className="form-group mb-3">
                                        <label className="text-black font-w500">
                                          Years of experience
                                        </label>
                                        <div className="contact-occupation">
                                          <input
                                            type="number"
                                            autoComplete="off"
                                            name="yearsOfExperience"
                                            value={
                                              editFormData.yearsOfExperience
                                            }
                                            onChange={handleEditFormChange}
                                            className="form-control"
                                            placeholder="Years of experience"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleEditFormSubmit}
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => setEditModal(false)}
                                    className="btn btn-danger"
                                  >
                                    {" "}
                                    <i className="flaticon-delete-1" /> Discard
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </Modal>
                      </Tab.Pane>

                      <Tab.Pane id="training" eventKey="Training">
                        <div className="pt-3">Training</div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;

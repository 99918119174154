import React, { useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Donut from "../Gymove/WorkoutStatistic/Donut";
import { format, parseISO } from "date-fns";
import {
  getRosterMatches,
  saveRoster,
} from "../../../services/SharedFunctions";
import { Modal, Button, Form, Alert } from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

import { useRoster } from "../../../context/RosterContext";
import round from "lodash/round";

const RunningChart = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/RunningChart"), 1000)
);
const CyclingChart = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/CyclingChart"), 1000)
);

const YogaTrainingChart = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/YogaTrainingChart"), 1000)
);
const PieChart = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/PieChart"), 1000)
);
const RedialBar = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/RadialBar"), 1000)
);

const Statistics = () => {
  const { rosters, selectedRoster, setSelectedRoster } = useRoster();
  const [matches, setMatches] = useState(null);
  useEffect(() => {
    getRosterMatches(selectedRoster._id).then((result) => {
      setMatches(result);
    });
    setApg(selectedRoster.target ? selectedRoster.target.apg : "");
    setEfr(selectedRoster.target ? selectedRoster.target.efr : "");
    setPpg(selectedRoster.target ? selectedRoster.target.ppg : "");
    setRpg(selectedRoster.target ? selectedRoster.target.rpg : "");
  }, [selectedRoster]);

  const matchsDate = matches
    ? matches.map((match) =>
        match.date ? format(parseISO(match.date), "dd/MM/yyyy") : ""
      )
    : [];

  const ptsDatasetEvolution = matches
    ? [
        {
          name: "Points",
          data: matches.map(
            (match) => match.twoPts * 2 + match.threePts * 3 + match.ft
          ),
          fontSize: 12,
        },
      ]
    : [];

  const rebDatasetEvolution = matches
    ? [
        {
          name: "Rebounds",
          data: matches.map((match) => match.rebound),
          fontSize: 12,
        },
      ]
    : [];

  const astDatasetEvolution = matches
    ? [
        {
          name: "Assists",
          data: matches.map((match) => match.assist),
          fontSize: 12,
        },
      ]
    : [];

  const progressDataset = matches
    ? {
        ppg: {
          title: "Points Per Game",
          color: "#A02CFA",
          data:
            matches.reduce(
              (sum, match) =>
                sum + match.twoPts * 2 + match.threePts * 3 + match.ft,
              0
            ) / matches.length,
          target: selectedRoster.target ? selectedRoster.target.ppg : 10,
        },
        rpg: {
          title: "Rebounds Per Game",
          color: "#FF3282",
          data:
            matches.reduce((sum, match) => sum + match.rebound, 0) /
            matches.length,
          target: parseInt(
            selectedRoster.target ? selectedRoster.target.rpg : 3
          ),
        },
        apg: {
          title: "Assists Per Game",
          color: "#FFBC11",
          data:
            matches.reduce((sum, match) => sum + match.assist, 0) /
            matches.length,
          target: parseInt(
            selectedRoster.target ? selectedRoster.target.apg : 2
          ),
        },
        efr: {
          title: "Efficiency Rating",
          color: "#FF9900",
          data: matches.reduce(
            (sum, match) =>
              sum +
              match.twoPts * 3 +
              match.threePts * 3 +
              match.ft +
              match.assist +
              match.rebound +
              match.steal +
              match.block -
              (match.twoPtsAttempt + match.threePtsAttempt + match.ftAttempt) -
              (match.twoPts + match.three + match.ft) +
              match.turnover,
            0
          ),
          target: parseInt(
            selectedRoster.target ? selectedRoster.target.efr : 10
          ),
        },
      }
    : [];

  const progressTotal = () => 10;

  const matchsDatasetEvolution = matches
    ? [
        {
          name: "2pts",
          data: matches.map((match) => match.twoPts),
          fontSize: 12,
        },
        {
          name: "3pts",
          data: matches.map((match) => match.threePts),
        },
        {
          name: "LF",
          data: matches.map((match) => match.ft),
        },
        {
          name: "Assist",
          data: matches.map((match) => match.assist),
        },
        {
          name: "Block",
          data: matches.map((match) => match.block),
        },
        {
          name: "Foul",
          data: matches.map((match) => match.foul),
        },
        {
          name: "Rebound",
          data: matches.map((match) => match.rebound),
        },
        {
          name: "Steal",
          data: matches.map((match) => match.steal),
        },
        {
          name: "Turnover",
          data: matches.map((match) => match.turnover),
        },
      ]
    : [];

  const [show, setShow] = useState(false);
  const [ppg, setPpg] = useState("");
  const [rpg, setRpg] = useState("");
  const [apg, setApg] = useState("");
  const [efr, setEfr] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    selectedRoster.target = { ppg, rpg, apg, efr };
    saveRoster(selectedRoster);
    handleClose();
  };

  const emojis = {
    welcome: (
      <svg
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="me-2"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
        <line x1="9" y1="9" x2="9.01" y2="9"></line>
        <line x1="15" y1="9" x2="15.01" y2="9"></line>
      </svg>
    ),
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="Statistics"
        motherMenu={selectedRoster.firstname + " " + selectedRoster.lastname}
      />

      <div className="row">
        <div className="col-xl-4">
          <div className="card">
            <div className="card-header pb-0 border-0">
              <span className="p-3 me-3 rounded bg-secondary">
                <svg
                  height="20px"
                  width="20px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#FFFFFF"
                >
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                </svg>
              </span>
              <div className="me-auto pr-3">
                <h4 className="text-black fs-20">Points</h4>
                <p className="fs-13 mb-0 ">
                  Track your scoring performance game by game.
                </p>
              </div>
            </div>
            <div className="card-body pb-0">
              <ReactApexChart
                options={{
                  chart: {
                    height: 400,
                    type: "area",
                    group: "social",
                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    width: [4],
                    colors: ["#A02CFA"],
                    curve: "smooth",
                  },
                  xaxis: {
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                    type: "text",
                    categories: matchsDate,
                  },
                }}
                series={ptsDatasetEvolution}
                type="area"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <div className="card">
            <div className="card-header pb-0 border-0">
              <span className="p-3 me-3 rounded bg-danger">
                <svg
                  height="20px"
                  width="20px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#FFFFFF"
                >
                  <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.59 5.58L20 12l-8-8-8 8z" />
                </svg>
              </span>
              <div className="me-auto pr-3">
                <h4 className="text-black fs-20">Rebounds</h4>
                <p className="fs-13 mb-0">
                  Aim to improve your positioning and timing to dominate the boards.
                </p>
              </div>
            </div>
            <div className="card-body pb-0">
              <ReactApexChart
                options={{
                  chart: {
                    height: 400,
                    type: "area",
                    group: "social",
                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    width: [4],
                    colors: ["#FF3282"],
                    curve: "smooth",
                  },
                  xaxis: {
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                    type: "text",
                    categories: matchsDate,
                  },
                }}
                series={rebDatasetEvolution}
                type="area"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <div className="card">
            <div className="card-header pb-0 border-0">
              <span className="p-3 me-3 rounded bg-warning">
                <svg
                  height="20px"
                  width="20px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#FFFFFF"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm2.59-11.41L11 12.17V7h-2v6.17l3.59 3.59L15 17.17l-5-5-5 5 1.41 1.41L11 15.17l5 5 1.41-1.41-7.41-7.41L12 7h2v4.17l2.59-2.59L17 10.17 12 15.17l-5-5L5.59 9.17 12 15.17l7.41-7.41L19 10.17l-4.41 4.41z" />
                </svg>
              </span>
              <div className="me-auto pr-3">
                <h4 className="text-black fs-20">Assists</h4>
                <p className="fs-13 mb-0">
                  Evaluate your assists to better understand your playmaking skills.
                </p>
              </div>
            </div>
            <div className="card-body pb-0">
              <ReactApexChart
                options={{
                  chart: {
                    height: 400,
                    type: "area",
                    group: "social",
                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    width: [4],
                    colors: ["#FFBC11"],
                    curve: "smooth",
                  },
                  xaxis: {
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                    type: "text",
                    categories: matchsDate,
                  },
                }}
                series={astDatasetEvolution}
                type="area"
                height={400}
              />
            </div>
          </div>
        </div>
        {/*
        <div className="col-xl-3">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="text-black fs-20 mb-0">Charts</h4>
                </div>
                <div className="card-body text-center">
                  <div className="man-chart mb-4">
                    <PieChart />
                    <svg
                      width="39"
                      height="74"
                      viewBox="0 0 39 74"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.5325 18.9448C27.7921 15.402 23.5761 13.6 18.0001 13.6C12.4241 13.6 8.2081 15.402 5.4677 18.9448C0.082099 25.908 2.8701 36.9376 2.9925 37.4C3.34508 38.8603 4.81456 39.7583 6.27486 39.4057C7.71986 39.0568 8.61712 37.6123 8.2897 36.1624C8.2897 36.0808 6.6985 27.8596 10.3297 23.3988L10.5269 23.1676V36.6588L9.1669 65.1508C9.0921 66.6164 10.1934 67.8771 11.6557 68H11.8801C13.2659 68.0095 14.4372 66.9758 14.6001 65.5996L17.5309 40.8H18.4625L21.4001 65.5996C21.563 66.9758 22.7343 68.0095 24.1201 68H24.3513C25.8136 67.8771 26.9149 66.6164 26.8401 65.1508L25.4801 36.6588V23.1744L25.6637 23.392C29.3357 27.88 27.7037 36.074 27.7037 36.176C27.3657 37.6407 28.279 39.1021 29.7437 39.44C31.2084 39.778 32.6697 38.8647 33.0077 37.4C33.1301 36.9376 35.9181 25.908 30.5325 18.9448Z"
                        fill="#ff9900"
                      ></path>
                      <path
                        d="M18.0001 12.24C21.3801 12.24 24.1201 9.49998 24.1201 6.12C24.1201 2.74002 21.3801 0 18.0001 0C14.6201 0 11.8801 2.74002 11.8801 6.12C11.8801 9.49998 14.6201 12.24 18.0001 12.24Z"
                        fill="#ff9900"
                      ></path>
                      <mask
                        id="mask0"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="19"
                        width="39"
                        height="55"
                      >
                        <path
                          d="M0 26.0017C0 24.1758 1.37483 22.6428 3.18995 22.4448L3.26935 22.4361C4.23614 22.3306 5.1115 21.8163 5.67413 21.023L6.13877 20.3679C7.48483 18.4701 10.3941 18.7986 11.2832 20.9487L11.4217 21.2836C12.2534 23.2951 14.9783 23.5955 16.2283 21.8136C17.323 20.253 19.6329 20.247 20.7357 21.8019L21.5961 23.0149C22.4113 24.1642 23.7948 24.7693 25.1921 24.5877L28.4801 24.1603C34.0567 23.4354 39 27.7777 39 33.4012V54.5C39 65.2695 30.2696 74 19.5 74C8.73045 74 0 65.2696 0 54.5V26.0017Z"
                          fill="#C4C4C4"
                        ></path>
                      </mask>
                      <g mask="url(#mask0)">
                        <path
                          d="M30.5324 18.9448C27.792 15.402 23.576 13.6 18 13.6C12.424 13.6 8.20798 15.402 5.46758 18.9448C0.0819769 25.908 2.86998 36.9376 2.99238 37.4C3.34496 38.8603 4.81444 39.7583 6.27474 39.4057C7.71974 39.0568 8.617 37.6123 8.28958 36.1624C8.28958 36.0808 6.69838 27.8596 10.3296 23.3988L10.5268 23.1676V36.6588L9.16678 65.1508C9.09198 66.6164 10.1932 67.8771 11.6556 68H11.88C13.2658 68.0095 14.4371 66.9758 14.6 65.5996L17.5308 40.8H18.4624L21.4 65.5996C21.5628 66.9758 22.7341 68.0095 24.12 68H24.3512C25.8135 67.8771 26.9148 66.6164 26.84 65.1508L25.48 36.6588V23.1744L25.6636 23.392C29.3356 27.88 27.7036 36.074 27.7036 36.176C27.3656 37.6407 28.2789 39.1021 29.7436 39.44C31.2083 39.778 32.6696 38.8647 33.0076 37.4C33.13 36.9376 35.918 25.908 30.5324 18.9448Z"
                          fill="#0B2A97"
                        ></path>
                        <path
                          d="M17.9999 12.24C21.3799 12.24 24.12 9.49998 24.12 6.12C24.12 2.74002 21.3799 0 17.9999 0C14.62 0 11.8799 2.74002 11.8799 6.12C11.8799 9.49998 14.62 12.24 17.9999 12.24Z"
                          fill="#0B2A97"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <ul className="d-flex flex-wrap">
                    <li className="me-5 mb-2">
                      <svg
                        className="me-2"
                        width={19}
                        height={19}
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width={19} height={19} rx="9.5" fill="#0B2A96" />
                      </svg>
                      <span className="fs-12 text-black">Blue Stat</span>
                    </li>
                    <li className="me-5 mb-2">
                      <svg
                        className="me-2"
                        width={19}
                        height={19}
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width={19} height={19} rx="9.5" fill="#FF9900" />
                      </svg>
                      <span className="fs-12 text-black">Green Stat</span>
                    </li>
                    <li className="me-5 mb-2">
                      <svg
                        className="me-2"
                        width={19}
                        height={19}
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width={19} height={19} rx="9.5" fill="#a02cfa" />
                      </svg>
                      <span className="fs-12 text-black"> Purple Start</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header flex-wrap border-0 pb-0">
                  <h4 className="text-black fs-20 mb-3">Progress</h4>
                  <Button variant="primary" onClick={handleShow}>
                    Set Target
                  </Button>

                  <Modal show={show} onHide={handleClose}>
                    <Form onSubmit={handleSubmit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Set Target</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                          <Form.Label>Target Points</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter PPG"
                            value={ppg}
                            onChange={(e) => setPpg(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Target Rebounds</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter RPG"
                            value={rpg}
                            onChange={(e) => setRpg(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Target Assists</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter APG"
                            value={apg}
                            onChange={(e) => setApg(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Target Efficiency</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter EFR"
                            value={efr}
                            onChange={(e) => setEfr(e.target.value)}
                          />
                        </Form.Group>
                        <p>
                          When setting objectives, it is essential to remain
                          flexible and adjust them based on the player's
                          development and current performances.
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" type="submit">
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                </div>
                <div className="card-body pt-0 pb-3">
                  <div className="row align-items-center">
                    <div className="col-lg-4 mb-lg-0 mb-4 text-center radialBar">
                      <div id="chart">
                        <ReactApexChart
                          options={{
                            chart: {
                              height: 280,
                              type: "radialBar",
                              offsetY: -10,
                            },
                            plotOptions: {
                              radialBar: {
                                startAngle: -135,
                                endAngle: 135,
                                dataLabels: {
                                  name: {
                                    fontSize: "16px",
                                    color: undefined,
                                    offsetY: 120,
                                  },
                                  value: {
                                    offsetY: 0,
                                    fontSize: "34px",
                                    color: "black",
                                    formatter: function (val) {
                                      return val + "%";
                                    },
                                  },
                                },
                              },
                            },
                            fill: {
                              type: "gradient",
                              colors: "#0B2A97",
                              gradient: {
                                shade: "dark",
                                shadeIntensity: 0.15,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 50, 65, 91],
                              },
                            },
                            stroke: {
                              lineCap: "round",
                              colors: "#0B2A97",
                            },
                            labels: [""],
                          }}
                          series={[0]}
                          type="radialBar"
                          height={280}
                        />
                      </div>
                      <p className="fs-14">
                        When setting objectives, it is essential to remain
                        flexible and adjust them based on the player's
                        development and current performances.{" "}
                      </p>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        {Object.entries(progressDataset).map(([key, value]) => (
                          <div className="col-sm-6" key={key}>
                            <div className="d-flex align-items-center mb-sm-5 mb-3">
                              <div className="d-inline-block relative donut-chart-sale me-3">
                                <Donut
                                  backgroundColor={value.color}
                                  backgroundColor2="rgba(236, 236, 236, 1)"
                                  value={parseInt(
                                    (!isNaN(value.data / value.target)
                                      ? round(
                                          value.data / value.target > 100
                                            ? 100
                                            : value.data / value.target,
                                          1
                                        )
                                      : 0) * 100
                                  )}
                                />
                                <small>
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip3)">
                                      <path
                                        d="M0.988957 17.0741C0.328275 17.2007 -0.104585 17.8386 0.0219821 18.4993C0.133361 19.0815 0.644693 19.4865 1.21678 19.4865C1.29272 19.4865 1.37119 19.4789 1.44713 19.4637L6.4592 18.5018C6.74524 18.4461 7.0009 18.2917 7.18316 18.0639L9.33481 15.3503L8.61593 14.9832C8.08435 14.7149 7.71474 14.2289 7.58818 13.6391L5.55804 16.1983L0.988957 17.0741Z"
                                        fill="#A02CFA"
                                      />
                                      <path
                                        d="M18.84 6.49306C20.3135 6.49306 21.508 5.29854 21.508 3.82502C21.508 2.3515 20.3135 1.15698 18.84 1.15698C17.3665 1.15698 16.1719 2.3515 16.1719 3.82502C16.1719 5.29854 17.3665 6.49306 18.84 6.49306Z"
                                        fill="#A02CFA"
                                      />
                                      <path
                                        d="M13.0179 3.15677C12.7369 2.8682 12.4762 2.75428 12.1902 2.75428C12.0864 2.75428 11.9826 2.76947 11.8712 2.79479L7.29203 3.88073C6.6592 4.03008 6.26937 4.66545 6.41872 5.29576C6.54782 5.83746 7.02877 6.20198 7.56289 6.20198C7.65404 6.20198 7.74514 6.19185 7.8363 6.16907L11.7371 5.24513C11.9902 5.52611 13.2584 6.90063 13.4888 7.14364C11.8763 8.87002 10.2639 10.5939 8.65137 12.3202C8.62605 12.3481 8.60329 12.3759 8.58049 12.4038C8.10966 13.0037 8.25397 13.9454 8.96275 14.3023L13.9064 16.826L11.3397 20.985C10.9878 21.5571 11.165 22.3064 11.7371 22.6608C11.9371 22.7848 12.1573 22.843 12.375 22.843C12.7825 22.843 13.1824 22.638 13.4128 22.2659L16.6732 16.983C16.8529 16.6919 16.901 16.34 16.8074 16.0135C16.7137 15.6844 16.4884 15.411 16.1821 15.2566L12.8331 13.553L16.3543 9.78636L19.0122 12.0393C19.2324 12.2266 19.5032 12.3177 19.7716 12.3177C20.0601 12.3177 20.3487 12.2114 20.574 12.0038L23.6243 9.16112C24.1002 8.71814 24.128 7.97392 23.685 7.49803C23.4521 7.24996 23.1383 7.12339 22.8244 7.12339C22.5383 7.12339 22.2497 7.22717 22.0245 7.43728L19.7412 9.56107C19.7386 9.56361 14.0178 4.18196 13.0179 3.15677Z"
                                        fill="#A02CFA"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip3">
                                        <rect
                                          width={24}
                                          height={24}
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </small>
                              </div>
                              <div>
                                <h4 className="fs-18 text-black">
                                  {value.title} (
                                  {(!isNaN(value.data / value.target)
                                    ? round(value.data / value.target, 1)
                                    : 0) * 100}
                                  %)
                                </h4>
                                <span>
                                  {value.data ? value.data.toFixed(1) : 0.0}/
                                  {value.target}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Alert variant="primary" className="solid alert-alt">
          {emojis.welcome}
          Customized goals tailored to your needs are coming{" "}
          <strong>soon!</strong>
        </Alert>
      </div>
    </Fragment>
  );
};

export default Statistics;
